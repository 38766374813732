import { configureStore } from '@reduxjs/toolkit'
import { counterSlice } from './slices/counterSlice'
import userDetailsReducer from "./slices/userDetailsSlice";
import trendsSlice from "./slices/trendsSlice";


export const store = configureStore({
  reducer: {
    counter: counterSlice,
    userDetails: userDetailsReducer,
    trendsVideos: trendsSlice,

  },
})