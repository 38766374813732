import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box,Typography  } from "@mui/material";
import SingleVideo from "../HomePage/SingleVideo";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setTrends, setTrendsInfo } from "../../store/slices/trendsSlice";
import { get } from "../../services";
import { IN_URL } from "../../BaseUrl";
// import SingleVideo from "./SingleVideo";

const ImmersedViewTrends = () => {
  const navigate = useNavigate();
  const location = useLocation();
//   const video = location.state?.video || null; // Get video data from navigation state
    
  const dispatch=useDispatch();

  const video=useSelector(state=>state.trendsVideos.videos);
  const videoInfo=useSelector(state=>state.trendsVideos.videoInfo);
  const touchStart = useRef(0);
  const touchEnd = useRef(0);
  const [showHint, setShowHint] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowHint(false), 3000); // Hide hint after 3s
    return () => clearTimeout(timer);
  }, []);

  const handleTouchStart = (e) => {
    touchStart.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    touchEnd.current = e.changedTouches[0].clientX;
    const delta = touchStart.current - touchEnd.current;

    if (Math.abs(delta) > 50) {
      // Navigate back to main trend page when swiped
      if(delta>0){
        if(videoInfo.index+1>=video.length){    
            handleLoadMoreVideos(videoInfo.page+1,0);
        }else{
            dispatch(setTrendsInfo({ page: videoInfo.page, index: videoInfo.index+1 }));
        }
        

      }else{
        if(videoInfo.index-1<0){
            if(videoInfo.page-1>0){
                handleLoadMoreVideos(videoInfo.page-1,7);
            }
        }else{
            dispatch(setTrendsInfo({ page: videoInfo.page, index: Math.max(videoInfo.index-1,0)}));
        }
        
      }
    }
};
const handleNextVideo = () => {
  if (videoInfo.index + 1 >= video.length) {    
    handleLoadMoreVideos(videoInfo.page + 1, 0);
  } else {
    dispatch(setTrendsInfo({ page: videoInfo.page, index: videoInfo.index + 1 }));
  }
};

const handlePrevVideo = () => {
  if (videoInfo.index - 1 < 0) {
    if (videoInfo.page - 1 > 0) {
      handleLoadMoreVideos(videoInfo.page - 1, 7);
    }
  } else {
    dispatch(setTrendsInfo({ page: videoInfo.page, index: Math.max(videoInfo.index - 1, 0) }));
  }
};
//   console.log(video);

//   if (!video) {
//     navigate(-1); // If no video data, redirect back
//     return null;
//   }

const handleLoadMoreVideos=(newPage,idx)=>{

  get(IN_URL + "videos", { params: { limit: 4, page: newPage } })
      .then((res) => {
          // setVideos(res.data.data);
          dispatch(setTrendsInfo({ page: newPage, index: idx }));
          dispatch(setTrends(res.data.data));
          
      })
      .catch(console.error);
}

useEffect(() => { 
  if (!video) {
    get(IN_URL + "videos", { params: { limit: 4, page: 1 } })
      .then((res) => {
        dispatch(setTrends(res.data.data));
      })
      .catch(console.error);
  }
}, [video]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgcolor: "black",
        zIndex: 1000,
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {video&&<SingleVideo vid={video[videoInfo?.index]} isImmersed radius={0} autoPlay={true} onNext={handleNextVideo}
  onPrev={handlePrevVideo} />}

      {/* Swipe Gesture Hint */}
      {showHint && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            width:"100%",
            height:"100%",
            animation: "fadeOut 3s ease-in-out forwards",
            "@keyframes fadeOut": {
              "0%": { opacity: 1 },
              "100%": { opacity: 0 },
            },
            background:"rgba(0,0,0,0.8)",

          }}

        >
          <Typography variant="h5" sx={{ mb: 1,mt:"40vh" }}>
            Swipe Left or Right
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              animation: "bounce 1.5s infinite",
              "@keyframes bounce": {
                "0%": { transform: "translateX(-15px)" },
                "50%": { transform: "translateX(15px)" },
                "100%": { transform: "translateX(-15px)" },
              },
            }}

          >
            <ArrowBackIos fontSize="large" />
            <Typography variant="body1">Swipe</Typography>
            <ArrowForwardIos fontSize="large" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImmersedViewTrends;
