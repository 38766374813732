import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Modal,
  Stack,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { Edit } from '@mui/icons-material';
import { get, put } from "../../services";
import { BASE_URL, IZ_USER } from "../../BaseUrl";
import { useFormik } from "formik";
import { ConfirmPassword, phone_number, postal_code, required } from "../../utils/validations";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonAddressModal from "../common/CommonAddressModal";

const DeliveryAddress = ({setAddressId}) => {
  const [showForm, setShowForm] = useState(false);

  const [searchParams] = useSearchParams();
  const productId = searchParams.get("id");
  const variantId = searchParams.get("v");
  
  
  const [addresses, setAddresses] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Track index for editing
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [editAddress, setEditAddress] = useState(null);





  const[refresh,setRefresh]=useState(1);

  const [navToLogin,setNavToLogin]=useState(false);
  const navigate=useNavigate();
  const handleEdit = (index) => {
    // Open the form modal
    console.log("Edit Address", addresses[index]);
    setEditAddress(addresses[index]);
    setShowForm(true); 

  };
  

  const handleAddNewAddress=()=>{
    if(navToLogin){

      navigate(`/login?c=1&id=${productId}&v=${variantId}`);
    }else{
      setEditAddress(null);
      setShowForm(true);
    }
    

  }

  useEffect(()=>{
    get(IZ_USER+'get-address').then((res)=>{
      const {data}=res;
      setAddresses(data);
    }).catch((e)=>{
      console.log(e);
      if(e?.response?.status===401)setNavToLogin(true);
    })
  },[refresh]);

  return (
    

    <Box
      sx={{
        maxWidth: "884px",
        backgroundColor: "#fff",
       
        "@media (max-width: 600px)": {
          padding: "5px",
        },
      }}
    >
     <Typography
  sx={{
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "16px",
    lineHeight: '29.26px',
    textUnderlinePosition: 'from-font',
    color: "#455F76",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
  }}
>
  Select Delivery Address
</Typography>

      {addresses.length > 0 ? (
        addresses?.map((address, index) => (
          <Box
            key={index}
            sx={{
              padding: "16px",
              border: "1px solid #BABABA",
              borderRadius: "8px",
              marginBottom: "15px",
              backgroundColor: "border: 1px solid var(--iz-grey-light, #BABABA)",
              
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
                "@media (max-width: 600px max-height: auto)": {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  "@media (max-width: 600px)": {
                    flexDirection: "row",
                    alignItems: "center",
                  },
                }}
              >
                {/* Updated Radio Button */}
                <Radio
                  checked={selectedAddressIndex === index}
                  onChange={() => {
                    setSelectedAddressIndex(index);
                    setAddressId(address.id);
                  }}
                />
                <Typography sx={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: "600" }}>
                  {address.manager_name}
                </Typography>
                {address.addressType && (
  <Box
    sx={{
      width: "56px",
      height: "25px",
      position: "relative",
      
      padding: "4px 6px",
      gap: "10px",
      borderRadius: "3px 3px 3px 3px",
      border: "0.5px solid #455F76", // Add border color
      opacity: 1, // Make it visible
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "17.07px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      color: "#455F76",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {address.addressType}
  </Box>
)}

                <Typography sx={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: "600" }}>
                  {address.phone}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  
                  cursor: "pointer",
                  color: '#455F76',
                  "@media (max-width: 600px)": {
                    marginLeft: "8px",
                  },
                }}
                onClick={() => handleEdit(index)}
              >
              <Edit sx={{ color: '#455F76',width:'15px',height:'15px' }} />   Edit
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                marginLeft: "33px",
                marginTop: '0px',
                fontSize: "16px",
                lineHeight: "24px",
                color: "#656565",
                fontWeight: "500",
                paddingLeft: "16px",
                
                "@media (max-width: 600px)": {
                  marginLeft: "0",
                },
              }}
            >
              {`${address.address }, ${address.landmark}, ${address.city.name}, ${address.state.name}, ${address.postal_code}`}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography 
  sx={{ marginBottom: '16px', fontFamily: 'Montserrat', fontSize: '16px', color: '#656565' }}
>
  No addresses added yet.
</Typography>

      )}
    
    
<a
  // href="#"
  onClick={handleAddNewAddress}
  style={{
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.5px',
    textAlign: 'left',
    marginTop: '60px',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    cursor: 'pointer',
    color:  '#FF944E',
   

  }}
>
  Add New Delivery Address
</a>
<hr
  style={{
    width: '100%',  // Make the line take up 100% of the width of its container
    maxWidth: '896px',  // Limit the width to 896px on larger screens
    margin: '16px auto',  // Center the line horizontally and add vertical spacing
    border: '0',  // Remove default border style
    borderTop: '2px solid #BABABA',  // Set color and thickness for the horizontal line
  }}
/>

<CommonAddressModal 
  open={showForm}
  editAddress={editAddress}
  setShowForm={setShowForm}
  setRefresh={setRefresh}
/>

    </Box>
  );
};

export default DeliveryAddress;
