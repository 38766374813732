import React, { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { FaPlay, FaPause } from "react-icons/fa";
import { IconButton, Box, Typography } from "@mui/material";

const Video = ({ src }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the video is visible
  });

  const handlePlay = () => {
    videoRef.current.play();
    setIsPlaying(true);
    setHasInteracted(true); // Mark that the user has interacted
  };

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  React.useEffect(() => {
    if (inView && hasInteracted) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [inView, hasInteracted]);

  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        width: "300px",
        height: "200px",
        margin: "10px",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: 3,
      }}
    >
      <video
        ref={videoRef}
        src="https://www.w3schools.com/html/mov_bbb.mp4"
        loop
        muted={false} // Allow sound
        onClick={togglePlayPause}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      
      {!hasInteracted && (
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
          onClick={handlePlay}
        >
          <FaPlay />
        </IconButton>
      )}
      {hasInteracted && (
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
          onClick={togglePlayPause}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </IconButton>
      )}

    </Box>
  );
};

export default Video;