import { Box, Typography } from "@mui/material";
import React from "react";
import { IN_ASSETS } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const data = [
  {
    title: "Grocery & Food",
    img: IN_ASSETS + "homepage-icons/Grocery.png",
    slug: "grocery-and-food",
  },
  {
    title: "Electronics & Accessories",
    img: IN_ASSETS + "homepage-icons/Electronics-in-focus.png",
    slug: "electronic",
  },
  {
    title: "Clothing, Fashion & Accessories",
    img: IN_ASSETS + "homepage-icons/Cloths.png",
    slug: "clothing",
  },
  {
    title: "Kitchen Appliances",
    img: IN_ASSETS + "homepage-icons/Kitchen%20Appliances.png",
    slug: "kitchen-appliances",
  },
];

const InFocus = () => {
  const navigate = useNavigate();

  return (
    <Box
  sx={{
    width: "100%",
    paddingY: { md: "86px", xs: "30px" },
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  }}
>
  <Box sx={{ width: "90%", maxWidth: "1200px", boxSizing: "border-box" }}>
    <Typography
      sx={{
        marginBottom: "32px",
        textAlign: "center",
        fontSize: { md: "36px", xs: "24px" },
        fontWeight: "600",
        fontFamily: "Montserrat",
      }}
    >
      In Focus Categories
    </Typography>
    <Box
      sx={{
        display: "grid",
        gap: { md: "20px", xs: "12px" },
        gridTemplateColumns: { md: "repeat(4, 1fr)", xs: "repeat(2, 1fr)" },
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      {data.map((item) => (
        <Box
          key={item.title}
          sx={{
            minHeight: { md: "300px", xs: "220px" }, // Adjusted height
            padding: { md: "20px", xs: "12px" },
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(213, 224, 255, 0) 0%, #D5E0FF 100%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            borderRadius: "10px",
            width: "100%",
            textAlign: "center",
          }}
          onClick={() => navigate("/category/" + item.slug)}
        >
          <Typography
            sx={{
              fontSize: { md: "20px", xs: "16px" }, // Smaller text to fit
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            {item.title}
          </Typography>
          <Box
            component="img"
            src={item.img}
            sx={{
              height: { md: "120px", xs: "80px" }, // Scaled down image
              objectFit: "contain",
              maxWidth: "90%", // Ensures images stay within box
            }}
          />
        </Box>
      ))}
    </Box>
  </Box>
</Box>

          )}
    

export default InFocus;
