import React, { useEffect } from "react";
import Header from "../components/HomePage/Header";
import { Box } from "@mui/material";
import SubHeader from "../components/HomePage/SubHeader";
import ClotheSection from "../components/HomePage/ClotheSection";
import Category from "../components/HomePage/Category";
import Feature from "../components/HomePage/Feature";
import InFocus from "../components/HomePage/InFocus";
import YouMayLike from "../components/HomePage/YouMayLike";
import ShopByCategory from "../components/HomePage/ShopByCategory";
import NewArivals from "../components/HomePage/NewArivals";
import DealOfTheDay from "../components/HomePage/DealOfTheDay";
import NewArriv from "../components/HomePage/NewArriv";
import Footer from "../components/HomePage/Footer";
import Hero from '../components/HomePage/Hero'
import Trend from "../components/HomePage/Trend";
import { toast } from "react-toastify";

const HomePage = () => {

  return (
    <Box
    sx={{
    height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column"}}
    >
      <SubHeader />
      <Hero/>
      {/* <---crosal----> */}
      <ClotheSection />
      <Trend page={1}/>
      <Feature fetchKey={Math.random()} /> {/* First instance with unique fetchKey */}
      {/* <---trend-----> */}
      {/*<NewArriv/>*/}
      <InFocus />
      <Trend page={2}/>
      <YouMayLike/> 
      {/* <ShopByCategory /> */}
      {/*<DealOfTheDay/>*/}
      <Feature fetchKey={Math.random()}/>
      {/*<NewArivals />*/}
      {/*<Category />*/}
      <Trend page={3}/>
      {/* <---------footer-----------> */}
    </Box>
    
  );
};

export default HomePage;
