import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  videos: null, // { id, name, email, etc. }
 videoInfo:{
    page:1,
    index:0,
 }
};

const trendsSlice = createSlice({
  name: "trendsVideos",
  initialState,
  reducers: {
    setTrends: (state, action) => {
      state.videos = action.payload;
    },
    clearTrends: (state) => {
      state.videos = null;

    },
    setTrendsInfo: (state, action) => {
      state.videoInfo = action.payload;
    },  
  },
});

export const { setTrends, clearTrends,setTrendsInfo } = trendsSlice.actions;
export default trendsSlice.reducer;
