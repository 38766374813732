import { Box, Divider, Typography, TextField, List, ListItem, IconButton } from '@mui/material';
import React from 'react';
import indiazonaIcon from '../../assets/indianzonaIcon.svg';
import lionIcon from '../../assets/lionIcon.svg';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Drawer from '@mui/material/Drawer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Person2Icon from '@mui/icons-material/Person2';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const MobileHeader = ({ inputValue, handleSearch, suggestions }) => {
  const [open, setOpen] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const DrawerList = (
    <Box sx={{ width: 200, display: 'flex', padding: '23px 18px', alignItems: 'flex-start', gap: "39px" }} role="presentation" onClick={toggleDrawer(false)}>
      <MenuOpenIcon onClick={toggleDrawer(true)} sx={{ width: '31px', height: '31px', color: 'primary.main' }} />
      <Box sx={{ display: 'flex', alignItems: 'start', gap: '20px', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <FavoriteBorderIcon sx={{ width: '17px', height: '17px' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: '500', fontFamily: 'Montserrat' }}>Wishlist</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <LocalMallOutlinedIcon sx={{ width: '17px', height: '17px' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: '500', fontFamily: 'Montserrat' }}>Cart</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <Person2Icon sx={{ width: '17px', height: '17px' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: '500', fontFamily: 'Montserrat' }}>Profile</Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      
      {showSearch ? (
        <Box sx={{ 
          padding: '13px', 
          width: "100%", 
          display: { xs: "flex", md: 'none' }, 
          alignItems: 'center', 
          backgroundColor: 'background.paper',
          flexDirection: 'column',
          gap: '10px'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '10px' }}>
            <IconButton onClick={toggleSearch}>
              <ArrowBackIcon />
            </IconButton>
            <TextField
              autoFocus
              value={inputValue}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search..."
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                    <SearchIcon />
                  </Box>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  height: "40px",
                  backgroundColor: "customBg.lightBlue",
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 14px",
                  },
                },
              }}
            />
          </Box>
          {suggestions.length > 0 && (
            <List sx={{
              width: '100%',
              maxHeight: '300px',
              overflowY: 'auto',
              backgroundColor: 'white',
              zIndex: 10,
            }}>
              {suggestions.map((option, index) => {
                if (option.type === "product") {
                  return (
                    <ListItem
                      key={index}
                      button
                      onClick={(e) => window.open(`/product/${option.id}`, "_blank")}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        padding: "10px",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      <SearchIcon />
                      <Typography>{option.name.substr(0, 100)}</Typography>
                    </ListItem>
                  );
                }
                return null;
              })}
            </List>
          )}
        </Box>
      ) : (
        <Box sx={{ 
          padding: '13px', 
          width: "100%", 
          display: { xs: "flex", md: 'none' }, 
          alignItems: 'center', 
          backgroundColor: 'background.paper', 
          justifyContent: 'space-between', 
          height: 'fit-content' 
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: "10px", width: 'fit-content', height: 'fit-content' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: "140px", height: '50px', overflow: 'hidden' }}>
              <img alt='indiazona' src={indiazonaIcon} style={{ width: '100%', height: 'auto' }} onClick={(e) => navigate("/")}/>
            </Box>
            <Divider sx={{ width: '1px', height:'50px',backgroundColor: "background.grey" }} />
            <Box sx={{ display: 'flex', alignItems: 'center', width: "70px", height: '32px', overflow: 'hidden' }}>
              <img alt='lionIcon' src={lionIcon} style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <IconButton onClick={toggleSearch}>
              <SearchIcon />
            </IconButton>
            <Box sx={{ width: '31px', height: 'fit-content', cursor: 'pointer' }}>
              <MenuOpenIcon onClick={toggleDrawer(true)} sx={{ overflow: 'hidden', width: '31px', height: '31px', color: 'primary.main' }} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileHeader;