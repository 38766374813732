import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { PROD_IMG_FUNC } from '../../BaseUrl'

const CategoryProducts = ({ products }) => {
  return (
    <Stack sx={{ p: 4, marginY: 4 }}>
      <Box
        sx={{
          display: "grid",
          gap: { md: "28px", xs: "8px" },
          gridTemplateColumns: { 
            lg: "repeat(4, minmax(0, 1fr))",
            md: "repeat(3, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            xs: "repeat(2, minmax(0, 1fr))" 
          },
          width: "100%",
          overflow: "hidden",
        }}
      >
        {products?.map((data) => (
          <Box
            key={data.title}
            sx={{
              height: "100%",
              padding: { md: "10.47px", xs: "6px" },
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              gap: { md: "13px", xs: "6px" },
              borderRadius: { md: "5px", xs: "3px" },
              border: "1px solid",
              borderColor: "primary.light",
              boxShadow: "0px 0px 1.07px 0px #919EAB33",
              '&:hover': { cursor: "pointer" },
              overflow: "hidden",
            }}
            onClick={(e) => window.open(`/product/${data.id}`, '_blank')}
          >
            {/* Image Container with Fixed Aspect Ratio */}
            <Box
              sx={{
                position: "relative",
                borderRadius: { md: "5px", xs: "3px" },
                backgroundColor: "background.default",
                width: "100%",
                aspectRatio: "1/1", // Fixed 1:1 aspect ratio
                overflow: "hidden", // Ensure images don't overflow
              }}
            >
              {data.discount && (
                <Box
                  sx={{
                    backgroundColor: data.discount === "New" ? "success.main" : "secondary.main",
                    left: { md: "4%", xs: "2px" },
                    top: { md: "4%", xs: "2px" },
                    position: "absolute",
                    padding: { md: "4px 13px", xs: "2px 7px" },
                    borderRadius: { md: "4px", xs: "2px" },
                    zIndex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      color: "customBg.white",
                    }}
                  >
                    {((data.tag_price - data.iz_price) * 100) / data.tag_price}
                  </Typography>
                </Box>
              )}
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Crop or expand to fill container
                  display: "block",
                  transition: "transform 0.3s ease",
                  '&:hover': {
                    transform: "scale(1.05)",
                  },
                }}
                src={PROD_IMG_FUNC(data.User.secure_id) + data.thumbnail_image_url}
                alt={data.title}
              />
            </Box>

            {/* Text Content */}
            <Box sx={{ 
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              padding: "8px",
            }}>
              <Typography
                sx={{
                  fontSize: { md: "17.45px", xs: "12px" },
                  fontWeight: "500",
                  fontFamily: "montserrat",
                  wordWrap: "break-word",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minHeight: "3em",
                }}
              >
                {data.product_name}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: { md: "10px", xs: "5px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "24.33px", xs: "12px" },
                    fontWeight: "700",
                    color: "success.main",
                    fontFamily: "Montserrat",
                    lineHeight: 1,
                  }}
                >
                  Rs {data.iz_price}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: "15.71px", xs: "8px" },
                    fontWeight: "400",
                    color: "secondary.main",
                    fontFamily: "Montserrat",
                    textDecoration: "line-through",
                    lineHeight: 1,
                  }}
                >
                  Rs {data.tag_price}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Stack>
  )
}

export default CategoryProducts;