import React, { useEffect } from "react"; // Import React
import { Route, Routes } from "react-router-dom"; // React Router imports
import ReactGA from "react-ga"; // Google Analytics import
import HomePage from "./layouts/HomePage";
import Footer from "./components/HomePage/Footer";
import Header from "./components/HomePage/Header";
import Dukan from "./layouts/Dukan";
import Category from "./layouts/Category";
import MainCategory from "./layouts/MainCategory";
import { Box } from "@mui/system";
import RegisterShopPage from "./layouts/RegisterShopPage";
import CampaignPage from "./layouts/CampaignPage";
import MyOrder from "./components/MyOrders/MyOrders";
import ProfileSettings from "./layouts/ProfilePage";
import OrderTracking from "./components/OrderTracking/Root";
import ViewOrder from "./components/ViewOrder/Root";
import Checkout from "./layouts/Checkout";
import HomePageLayout from "./layouts/HomePageLayout";
import MyCart from "./components/Cart/Cart";
import AboutUsPage from "./components/common/AboutUsPage";
import VendorFAQs from "./components/common/VendorFAQs";
import CustomerFAQs from "./components/common/CustomerFAQs";
import OrderCancellationFAQs from "./components/common/OrderCancellationFAQs";
import ReturnExchangePolicy from "./components/common/ReturnExchangePolicy";
import ComplianceUndertaking from "./components/common/ComplianceUndertaking";
import PrivacyPolicyPage from "./components/common/PrivacyPolicyPage";
import TermsOfUsePage from "./components/common/TermOfUsage";
import RegisterPage from "./components/authPage/RegisterPage.jsx";
import LoginPage from "./components/authPage/LoginPage.jsx";
import SingleTrendsVideo from "./pages/trends/SingleTrendsVideo.jsx";
import Trend from "./components/HomePage/Trend.jsx"
import SingleVideo from "./components/HomePage/SingleVideo.jsx";
import TrendsShortVideos from "./pages/trends/TrendsShortVideos.jsx";
import TestTrendsVideo from "./pages/trends/TestTrendsVideo.jsx";
import ShareButton from "./components/common/ShareButton.jsx";

import SEO from "./SEO";
import ForgotPassword from "./components/authPage/ForgotPassword.jsx";
import ResetPassword from "./components/authPage/ResetPassword.jsx";
import VideoList from "./components/trends/VideoList.jsx";
import ImmersedViewTrends from "./components/trends/ImmersedViewTrends.jsx";
import Cart from "./pages/cart/Cart.jsx";



// Initialize Google Analytics
ReactGA.initialize("G-EZWBBFRGKS");

function App() {
  useEffect(() => {
    // Track page views for any route changes (important for SPA)
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []); // Empty dependency array to ensure it runs once when the component is mounted

  return (
    <Routes>
      <Route path="/" element={<HomePageLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/dukan" element={<Dukan />} />
        <Route path="/category/:slug" element={<Category />} />
        <Route path="/product/:id?" element={<MainCategory />} />
        <Route path="/checkout" element={<Checkout />} />
        {/* <Route path="/trends" element={<Trend/>} /> */}
        <Route path="/order-tracking/:orderCode/:orderId" element={<OrderTracking />} />
        <Route path="/my-orders" element={<MyOrder />} />
        <Route path="/register-shop" element={<RegisterShopPage />} />
        {/* <Route path="/cart" element={<MyCart />} /> */}
        <Route path="/cart" element={<Cart />} />
        <Route path="/return" element={<OrderTracking />} />
        <Route path="/profile/*" element={<ProfileSettings />} />
        <Route path="/view-order" element={<ViewOrder />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/sellers-faqs" element={<VendorFAQs />} />
        <Route path="/customer-faqs" element={<CustomerFAQs />} />
        <Route path="/compliance" element={<ComplianceUndertaking />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfUsePage />} />
        <Route path="/policies">
          <Route path="cancellation" element={<OrderCancellationFAQs />} />
          <Route path="return-exchange" element={<ReturnExchangePolicy />} />
        </Route>
        <Route path="/registerseller" element={<RegisterShopPage />} />
        <Route path="/campaign" element={<CampaignPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {/* <Route path="/trends" element={<SingleTrendsVideo />} /> */}
        {/* <Route path="/trends" element={<TestTrendsVideo />} /> */}
        <Route path="/trends" element={<ImmersedViewTrends />} />
        <Route path="/short-video" element={<VideoList />} />
      </Route>
    </Routes>
  );
}

export default App;
