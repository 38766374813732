import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import CheckoutComponent from '../components/Checkout/Heading'; // Header component
import DeliveryAddress from '../components/Checkout/DeliveryAddress'; // DeliveryAddress component
import CouponOffers from '../components/Checkout/AvailableOffers'; // CouponOffers component
import Payment from '../components/Checkout/PaymentOptions'; // Payment component
import OrderSummary from '../components/Checkout/OrderSummary'; // OrderSummary component
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { get, post } from '../services';
import { IN_CART, IN_PRODUCT, IN_URL, PROD_IMG_FUNC } from '../BaseUrl';
import { toast } from 'react-toastify';

const Checkout = () => {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("id");
  const variantId = searchParams.get("v");
  const isCart = searchParams.get("c");


  const navigate=useNavigate();
  

  const [discount, setDiscount] = useState(0);
  const [orderDetails, setOrderDetails] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [productDetails,setProductDetails]=useState(null);
  const [selectedVariant,setSelectedVariant]=useState(null);
  const [image,setImage]=useState([]);
  const [coupon,setCoupon] = useState();
  const [imgPath,setImgPath]=useState(null);
  const [pricing,setPricing]=useState({});
  const [loading,setLoading]=useState(false);


  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleContinueShopping = () => {
    // Add logic to navigate to the shopping page or home page
    // console.log("Navigating to Continue Shopping...");
    navigate('/');
    // Example: window.location.href = '/shop';
  };

  const handleTrackOrder = () => {
    // Add logic to navigate to the order tracking page
    // console.log("Navigating to Track Order...");
    // Example: window.location.href = '/track-order';
    navigate('/my-orders');
  };


  useEffect(()=>{
    // toast.error("test notification");
    if(variantId&&productId){
      
      get(IN_PRODUCT+'/get-product-details/'+productId).then((res)=>{
        const {data}=res;
        // console.log(data);
        setProductDetails(data);
        // setSelectedVariant(data.ProductVariants[0]);
        const variant=data.ProductVariants.find((data)=>{
          return data.id==variantId;
        });
        
        setSelectedVariant(variant);
  
        setPricing({
          tagPrice:data?.tag_price,
          discount:data?.tag_price-variant?.iz_price,
          total:variant?.iz_price,
        });
     
        const imageUrl=PROD_IMG_FUNC(data.User.secure_id);
        setImgPath(imageUrl);
        if(variant?.image_url){
          setImage([imageUrl+variant?.image_url]);
        }else{
          setImage([imageUrl+data.thumbnail_image_url]);
        }
        
      }).catch((e)=>{
        toast.error("Product is not found");
      });
      
    }else if(isCart){
      get(IN_CART).then((res)=>{
        const {data}=res;
        // setCartItems(data);
        let price=0;
        let discount=0;
        let total=0;
        let image=[];
        data.forEach((item)=>{
          price+=(Number(item?.ProductVariant?.Product?.tag_price)*Number(item?.quantity));
          total+=(Number(item?.ProductVariant?.iz_price)*Number(item?.quantity));
          const link=PROD_IMG_FUNC(item?.ProductVariant?.Product?.User?.secure_id)+item?.ProductVariant?.Product?.thumbnail_image_url;
          image.push(link);
        });
        discount=price-total;
        setPricing({
          tagPrice:price,
          discount:discount,
          total:total,
        });
        setImage(image);
      }).catch((e)=>{
        if(e.response.status===401){
          toast.warning('Please login to continue');
          navigate('/login');
        }
      })
    }
  },[]);

  //const applyDiscount = (discountCode) => {
    // toast.error("Invalid coupon");
  //};

  if(!isCart && (!productId || !variantId)){
    
    return <Navigate to='/'/>
  }



  const handleOrder=()=>{
    setLoading(true);
    if(!addressId){
      toast.error("Please select an address");
      setLoading(false);
      return;
    }
    let payload={};
    if(isCart){
      payload={
        "payment_mode":"cod",
        "address_id":addressId,
        "is_cart":1
      }
    }else{
      payload={
        "payment_mode":"cod",
        "address_id":addressId,
        // "address_id":2,
        "order_items":[
            {
                "product_id":productId,
                "variant_id":variantId,
                "qty":1
    
            }
        ]
      }
    }
     

    post(IN_URL + 'orders/create-order', payload)
  .then((res) => {
    const { data } = res;
    console.log(data);
    setOrderDetails(data);
    handleModalOpen();
    setLoading(false);
  })
  .catch((e) => {
    // console.log(e);
    setLoading(false);
    // Check if the error status is 401 (Unauthorized)
     if (e?.response?.status === 401) {
      toast.error("Please create an account or login.");
      window.location.href = "/login";
    }else{
      toast.error("Something went wrong please try again later");
    }

  });



  }



  return (
    <>
      <CheckoutComponent />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          background: "linear-gradient(to bottom,#C7DEEF, #ffffff)"

        }}
      >
        <Container
          maxWidth={false}
          sx={{
            width: '1400px',
            height: 'auto',
            backgroundColor: '#fff',
            padding: '16px',
            
            marginTop: '40px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: '16px',
            }}
          >
            {/* Left Section */}
            <Box sx={{ flex: 1 }}>
              <DeliveryAddress setAddressId={setAddressId} />
              <CouponOffers onApplyDiscount={coupon} />
              <Payment />
            </Box>

            {/* Right Section */}
            <Box sx={{ flex: 0.4 }}>
            <OrderSummary
            image={image}
            selectedVariant={selectedVariant}
            productDetails={productDetails}
            handleOrder={handleOrder}
            pricing={pricing}
            loading={loading}
          />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Modal for Order Confirmation */}
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="order-success-title"
        aria-describedby="order-success-description"
      >
        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "520px" }, // Responsive width
            height: { xs: "auto", md: "402px" }, // Adjust height for smaller screens
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: { xs: "15px", md: "20px" }, // Adjust padding
            margin: "auto",
            marginTop: { xs: "20%", sm: "15%", md: "7%" }, // Adjust top margin
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* Add the icon */}
          <img
            src="/assets/images/Group.png" // Replace with the actual path to the uploaded image
            alt="Success Icon"
            style={{
              width: "80px", // Adjusted for smaller screens
              height: "80px",
              marginBottom: "20px",
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "18px", md: "24px" }, // Responsive font size
              fontWeight: 700,
              marginBottom: "16px",
            }}
          >
            Your order has been successfully placed!
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "14px", md: "16px" }, // Responsive font size
              color: "#333",
              marginBottom: "8px",
            }}
          >
            Order ID: <strong>{orderDetails?.order_id}</strong>
          </Typography>
          {/* <Typography
            variant="body1"
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "14px", md: "16px" }, // Responsive font size
              color: "#333",
              marginBottom: "30px",
            }}
          >
            Expected Delivery: <strong>31st Dec, 2024</strong>
          </Typography> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Stack buttons on small screens
              gap: "15px", // Add spacing between buttons
              justifyContent: { md: "space-between" },
              alignItems: "center",
              width: "100%",
              padding: { xs: "0", md: "0 50px" }, // Adjust padding
              mt:4,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#FF944E",
                color: "#FF944E",
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", md: "14px" }, // Responsive font size
                fontWeight: 600,
                padding: "10px 20px",
                textTransform: "none",
                "&:hover": {
                  borderColor: "#FF944E",
                  backgroundColor: "rgba(255, 148, 78, 0.1)",
                },
              }}
              onClick={handleContinueShopping}
            >
              Continue Shopping
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FF944E",
                fontFamily: "Montserrat",
                fontSize: { xs: "12px", md: "14px" }, // Responsive font size
                fontWeight: 600,
                padding: "10px 20px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FF944E",
                },
              }}
              onClick={handleTrackOrder}
            >
              Track your Order
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Checkout;
