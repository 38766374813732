import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import RedeemIcon from "@mui/icons-material/Redeem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, post } from "../services";
import { toast } from "react-toastify";
import { IN_CART, IN_PRODUCT, PROD_IMG_FUNC } from "../BaseUrl";
import ShareButton from "../components/common/ShareButton";
import { AssignmentReturn, SwapHoriz, CurrencyRupee } from '@mui/icons-material';
import SubheaderCommon from "../components/common/SubheaderCommon";
import LocalstorageService from "../utils/helpers/localstorage-services";




const MainCategory = () => {
  const { id } = useParams();

  const [productDetails, setProductDetails] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedVariantAttr, setSelectedVariantAttr] = useState({});

  const [image, setImage] = useState(null);
  const [imgPath, setImgPath] = useState(null);
  const [similarProducts, setSimilarProducts] = useState(null);
  const [postalCode, setPostalCode] = useState("");
  const [eddContent, setEddContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [oLoading, setOLoading] = useState('');
  const [variant, setVariant] = useState({});
  const navigate = useNavigate();


//  const data = [
//   "men",
//   "women",
//   "winter",
//   "Home",
//   "Kitchen",
//   "Office",
//   "Decorative",
//   "men",
//   "women",
//   "winter",
//   "Home",
//   "Kitchen",
//   "Office",
// ];

  //const handleAddToCart = () => {
  //navigate('src/components/Cart/Cart.jsx'); // Adjust the path based on your routing structure
  //};

  const productVariantArrangement = (variants) => {
    let variantData = {}; // take a object for keeping keys unique
    variants?.forEach((data) => {
      const varDatas = data?.product_attribute_ids;
      varDatas?.forEach((varData) => {
        if (variantData[varData.attribute]) {
          if(variantData[varData.attribute].find((entry)=>varData.values===entry)){
            return;
          }
          variantData[varData.attribute].push(varData.values);
        } else {
          variantData[varData.attribute] = [varData.values];
        }
      })
    });
    // console.log("calculated variantData",variantData);
    return variantData;
    // return [];
    
  }

  const returnPolicy={
    1:"3 days return",
    2:"7 days return",
    3:"14 days return"
  }

  const exchangePolicy={
    1:"No exchange",
    2:"7 days exchange"
  }
  useEffect(() => {

    if (id) {
      get(IN_PRODUCT + '/get-product-details/' + id).then((res) => {
        const { data } = res;
        // console.log(data);
        setProductDetails(data);
        setSelectedVariant(data.ProductVariants[0]);
        // console.log("selected variant",data.ProductVariants[0].product_attribute_ids);
        let temp={};
        data.ProductVariants[0]?.product_attribute_ids?.forEach((attr)=>{
          temp= {
            ...temp,
            [attr.attribute]:attr?.values
          }
        });
        setSelectedVariantAttr(temp);
        // console.log("temp",temp);
        const imageUrl = PROD_IMG_FUNC(data.User.secure_id);
        setImgPath(imageUrl);
        setVariant(productVariantArrangement(data.ProductVariants));
        
        setImage(imageUrl + data.thumbnail_image_url);
      }).catch((e) => {
        toast.error("Product not found");
        console.log("error in product details page",e);
      });

    }

  }, []);

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const formattedDate = new Date(`${year}-${month}-${day}`); // Parse into Date object
    const options = { day: "numeric", month: "short", weekday: "long" };
    const dateStringFormatted = formattedDate.toLocaleDateString("en-US", options);
    const [weekday, dayMonth] = dateStringFormatted.split(", "); // Separate weekday and day-month
    return `Delivery by ${dayMonth}, ${weekday}`;
  };

  const handleCheckEdd = () => {
    setLoading(true);
    if (postalCode.length !== 6) {
      setEddContent('Please enter a valid pin code');
      setLoading(false);
      return;
    }
    const params = {
      id: productDetails.id,
      code: postalCode,
    }
    get(IN_PRODUCT + 'get-edd', { params }).then((res) => {
      const { data } = res;
      setEddContent(formatDate(data));
      setLoading(false);
    }).catch((e) => {
      console.log(e);
      setLoading(false);
    })
  }

  useEffect(() => {
    if (productDetails) {
      const params = {
        'category': productDetails?.Item?.ItemCategory?.id,
        'limit': 10,
      }
      get(IN_PRODUCT + '/get-similar-products', { params }).then((res) => {
        const { data } = res;
        setSimilarProducts(data.data);
      }).catch((e) => {
        console.log(e);
      });
    }
  }, [productDetails]);

  const round = (num) => {
    return Math.round(num * 10) / 10;
  }

  const findAttrAndValue = (variantToSearch) => {
    const productVariant = productDetails?.ProductVariants
    for (let i = 0; i < productVariant.length; i++) {
      const variant = productVariant[i]?.product_attribute_ids;
      
      if(JSON.stringify(variantToSearch)===JSON.stringify(variant)){
        return productVariant[i];
      }

      // for (let j = 0; j < variant.length; j++) {

      //   if (variant[j]?.attribute === attribute && variant[j]?.values === value) {
      //     return productVariant[i];
      //   }
      // }
    }
    return null;
  }

  const handleSelectVariant = (attribute, value, key) => {
    // console.log(attribute,"-->",value);
    const temp = {
      ...selectedVariantAttr,
      [attribute]: value
    }
    // temp={
    // Color:"red",
    // Size:'M',
    // Material:'Cotton'
    // }
    setSelectedVariantAttr(temp);
    const variantToSearch=Object.entries(temp).map(([attribute, value]) => ({ 
      "attribute": attribute, 
      "values": value 
  }));
    const result = findAttrAndValue(variantToSearch);

    // console.log("selected variant",temp);
    if(result){
      setSelectedVariant(result);
    }
    else{
      toast.warning("Variant not available");
    }
  }
  const handleAddToCart=()=>{
    setOLoading('cart');
    if(!selectedVariant){
      toast.warning("Selected variant is not available");
      setOLoading('');
      return;
    }
 
    const payload={
      "quantity":1,
      "is_no_return_discount":0,
      "product_variant_id":selectedVariant.id
    }
    
    post(IN_CART,payload).then((res)=>{

      toast.success("Added to cart successfully");
      setOLoading('');
    }).catch((e)=>{
      if(e.response.status===401){
        setOLoading('');
        toast.warning("Please login to add to cart");
        navigate('/login');
      }
    })

  }

  return (
    <Box>
      <SubheaderCommon />
      <Box sx={{ padding: { md: "20px 56px" }, width: "100%" }}>
        <Box
          sx={{
            padding: { xs: "16px", md: "20px" }, // Increased mobile padding
            width: "100%",
            boxShadow: `0px 7.39px 14.78px -2.46px #919EAB1F, 0px 0px 1.23px 0px #919EAB33`,
            backgroundColor: "background.paper", // Use theme color
            borderRadius: "8px", // Consistent rounded corners
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: { md: "start", xs: "center" },
              gap: { xs: "16px", md: "24px" }, // Responsive gap
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "16px", md: "25px" },
                width: "100%",
                maxWidth: { md: "500px", xs: "100%" }, // Constrain max width on desktop
              }}
            >

              {/* Main Image Container */}
              <Box
                sx={{
                  padding: { xs: "16px", md: "24px" }, // Increased mobile padding
                  border: "1px solid #BABABA",
                  width: "100%",
                  aspectRatio: "1/1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //backgroundColor: "#f8f8f8",
                  borderRadius: "8px", // Matching border radius
                  overflow: "hidden", // Keep image within container
                  position: "relative",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    objectFit: "contain",
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    transition: "transform 0.3s ease",
                    '&:hover': {
                      transform: "scale(1.05)",
                    }
                  }}
                  src={image}
                />
              </Box>

              {/* Thumbnail Gallery */}
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: "12px", md: "16px" }, // Increased mobile gap
                  overflowX: "auto",
                  width: "100%",
                  paddingBottom: "12px", // More scrollbar space
                  minHeight: "96px",
                  "&::-webkit-scrollbar": {
                    height: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#8CB89F",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                {productDetails?.ProductImages?.map((img) => {
                  if (!img?.image_url?.includes("mp4")) {
                    return (
                      <Box
                        key={img.image_url}
                        onClick={() => setImage(imgPath + img.image_url)}
                        sx={{
                          flexShrink: 0,
                          border: `2px solid ${image === imgPath + img.image_url ? '#8CB89F' : '#BABABA'}`,
                          padding: "4px",
                          borderRadius: "6px",
                          cursor: "pointer",
                          transition: "all 0.2s ease",
                          '&:hover': {
                            borderColor: "#8CB89F",
                            transform: "translateY(-2px)"
                          },
                          aspectRatio: "1/1",
                          width: { xs: "92px", md: "100px" }, // Slightly larger thumbnails
                          backgroundColor: "#f8f8f8",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                            padding: "4px", // Add space around thumbnail images
                          }}
                          src={imgPath + img.image_url}
                        />
                      </Box>
                    );
                  }
                  return null;

                })}
              </Box>

              {/* Action Buttons */}
              <Box
                sx={{
                  display: "grid",
                  gap: { xs: "12px", md: "13px" },
                  width: "100%",
                  align: "center",
                  gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, // Single column on mobile
                  justifyContent: { xs: "center", md: "start" }, // Center horizontally on mobile
                  alignItems: { xs: "center", md: "start" }, // Center vertically on mobile
                  margin: { xs: "auto", md: "0" },
                }}
             
              >
                <Button
                  sx={{
                    py: { xs: 2, md: 2.5 },
                    px: { xs: 2, md: 4 },
                    color: "white",
                    fontWeight: "700",
                    fontSize: { xs: "16px", md: "18px" },
                    lineHeight: "24px",
                    backgroundColor: "#FF944E",
                    borderRadius: "4px",
                    '&:hover': {
                      backgroundColor: "#de8246",
                    },
                  }}
                  onClick={handleAddToCart}
                >
                  <LocalGroceryStoreIcon />
                  {oLoading==='cart' ? <CircularProgress size={20} sx={{ color: "white" }} /> : <Typography>Add To Cart</Typography>}
                </Button>
                <Button
                  fullWidth
                  sx={{
                    py: { xs: 2, md: 2.5 },
                    px: { xs: 2, md: 4 },
                    color: "white",
                    fontWeight: 700,
                    fontSize: { xs: "16px", md: "18px" },
                    backgroundColor: "#8CB89F",
                    borderRadius: "4px",
                    '&:hover': {
                      backgroundColor: "#7aa58b",
                    },
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                  onClick={() => navigate(`/checkout?id=${productDetails?.id}&v=${selectedVariant?.id}`)}
                >
                  <FlashOnIcon fontSize="small" />
                  <Typography variant="button">Buy Now</Typography>
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: { md: "", xs: "17px 12px" },
                width: "100%",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {/* <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "#A1A1A1",
                    }}
                  >
                    {`Home > mobile & accessories > mobiles > samsung >`}
                    </Typography> */}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Montserrat",
                      fontSize: { md: "16px", xs: "12px" },
                      color: "#455F76",
                    }}
                    component="span"
                  >

                    {productDetails?.Brand?.name?.toUpperCase()}
                  </Typography>

                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center", // Aligns the text and button vertically
                    gap: 1, // Adds space between the text and button
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: { md: "16px", xs: "12px" },
                      color: "#455F76",
                    }}
                  >
                    Share
                  </Typography>
                  <ShareButton
                    shareUrl={`https://indiazona.in/products/${productDetails?.id}`}
                    productName={productDetails?.product_name}
                  />
                </Box>

              </Box>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: { md: "28px", xs: "16px" },
                }}
              >
                {productDetails?.product_name}
              </Typography>
              {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <StarIcon
                  sx={{ width: "36px", height: "36px", color: "#F2C210" }}
                />
                <StarIcon
                  sx={{ width: "36px", height: "36px", color: "#F2C210" }}
                />
                <StarIcon
                  sx={{ width: "36px", height: "36px", color: "#F2C210" }}
                />
                <StarBorderIcon
                  sx={{ width: "36px", height: "36px", color: "#A1A1A1" }}
                />
                <Typography>10 Reviews</Typography>
              </Box> */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {/* Price and Discount Row */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {/* Selling Price */}
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: { md: "32px", xs: "18px" }, // Responsive font size
                      color: "#4CAF50", // Green color for selling price
                    }}
                  >
                    ₹ {selectedVariant?.iz_price}
                  </Typography>

                  {/* Discount Percentage */}
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: { md: "18px", xs: "12px" }, // Responsive font size
                      color: "#ff944e",
                    }}
                  >
                    ({productDetails &&
                      round(
                        ((productDetails?.tag_price - selectedVariant?.iz_price) * 100) /
                        productDetails?.tag_price
                      )}
                    % OFF)
                  </Typography>
                </Box>

                {/* MRP and Inclusive of Taxes */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    flexWrap: "wrap", // Ensures proper wrapping on smaller screens
                  }}
                >
                  {/* MRP */}
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: { md: "16px", xs: "12px" }, // Responsive font size
                      color: "#000000", // Black color for MRP
                    }}
                  >
                    MRP:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: { md: "16px", xs: "12px" }, // Responsive font size
                      color: "#ff944e", // Black color for strikethrough MRP
                      textDecoration: "line-through", // Apply strike-through
                    }}
                  >
                    ₹ {productDetails?.tag_price}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                      fontSize: { md: "14px", xs: "12px" }, // Responsive font size
                      color: "#BABABA", // Grey color for Inclusive of all taxes
                      marginLeft: { xs: "0", md: "10px" }, // Adjust spacing for larger screens
                    }}
                  >
                    (Inclusive of all taxes)
                  </Typography>
                </Box>
              </Box>

              {/* <Typography
                sx={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                }}
              >
                EMI starts at ₹ 923. No cost EMI Options
              </Typography> */}
              {
                Object?.keys(variant)?.map((key) => {
                  //  `${key}: ${variantData[key]}`;


                  return (
                    <Box key={variant[key]}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                          fontSize: { md: "16px", xs: "12px" },
                          color: "#a1a1a1",
                        }}
                      >
                        {key}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center",flexWrap:"wrap", gap: "12px", mt: 1 }} >
                        {
                          variant[key]?.map((value,ind) => {
                            return (
                              <Button
                                variant="outlined"
                                key={`${value}Of${key}`}
                                color={selectedVariantAttr[key] === value ? 'secondary' : 'primary'}
                                sx={{
                                  fontSize: { md: "16px", xs: "12px" },
                                  fontWeight: "600",
                                  fontFamily: "Montserrat",
                                }}
                                onClick={() => handleSelectVariant(key, value, `${value}Of${key}`)}
                              >
                                {value}
                              </Button>
                            )
                          })
                        }
                      </Box>
                    </Box>
                  )

                })
              }

              {/* <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  color: "#455F76",
                }}
              >
                Available Color: 2
              </Typography> */}
              {/* <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  color: "#A1A1A1",
                }}
              >
                Eligible offers:
              </Typography> */}

              {/* <Box sx={{ display: "flex", gap: "10px" }}>
                <Box
                  sx={{
                    width: "284px",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #BABABA",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "start", gap: "12px" }}
                  >
                    <RedeemIcon />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      Get 10% off on Minimum order value Rs 1499
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      padding: "7px",
                      gap: "12px",
                      borderRadius: "10px",
                      justifyContent: "center",
                      border: "1px dashed #e1e1e1",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        lineHeight: "17.07px",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#f89b43",
                      }}
                    >
                      NEW YEAR SAVE 10
                    </Typography>
                    <ContentCopyIcon sx={{ width: "15px", color: "#f89b43" }} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "284px",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #BABABA",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "start", gap: "12px" }}
                  >
                    <RedeemIcon />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      Get 10% off on Minimum order value Rs 1499
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      padding: "7px",
                      gap: "12px",
                      borderRadius: "10px",
                      justifyContent: "center",
                      border: "1px dashed #e1e1e1",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        lineHeight: "17.07px",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#f89b43",
                      }}
                    >
                      NEW YEAR SAVE 10
                    </Typography>
                    <ContentCopyIcon sx={{ width: "15px", color: "#f89b43" }} />
                  </Box>
                </Box>
              </Box> */}

              {/*<Box sx={{ display: "flex", gap: "4px" }}>
                <LocalOfferIcon sx={{ color: "#8CB89F" }} />
                <Typography>
                  Bank Offer 5% Cashback on Axis Bank Credit Card
                  <Link>T&C</Link>
                </Typography>
              </Box> */}

              {/*<Box sx={{ display: "flex", gap: "4px" }}>
                <LocalOfferIcon sx={{ color: "#8CB89F" }} />
                <Typography>
                  Bank Offer 15% Cashback on EMI purchase with any Bank Credit
                  Card
                  <Link>T&C</Link>
                </Typography>
              </Box>*/}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile, row on desktop
                  alignItems: { xs: "flex-start", md: "center" }, // Align items to start on mobile, center on desktop
                  gap: { xs: "8px", md: "16px" }, // Add gap between elements
                }}
              >
                {/* Search Box and Button */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    height: "32px",
                    position: "relative",
                    order: 1, // Ensure this is first on both mobile and desktop
                  }}
                >
                  <TextField
                    placeholder="Write pin code here to check"
                    type="number"
                    fullWidth
                    sx={{
                      height: "32px",
                      padding: 0,
                      "& .MuiInputBase-root": {
                        height: "32px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "6px",
                        fontSize: { xs: "12px", md: "14px" }, // Smaller font size on mobile
                      },
                      "& .MuiAutocomplete-listbox": {
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        scrollbarWidth: "none",
                        "-ms-overflow-style": "none",
                      },
                      width: { xs: "100%", md: "300px" }, // Wider on desktop, full width on mobile
                    }}
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />

                  <Button
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "5px",
                      color: "white",
                      backgroundColor: "primary.main",
                      padding: "6px",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                      marginLeft: { xs: "8px", md: "16px" }, // Add margin for spacing
                    }}
                    onClick={handleCheckEdd}
                  >
                    {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : "Check"}
                  </Button>
                </Box>

                {/* EDD Content */}
                <Typography
                  sx={{
                    color: "tertiary.main",
                    fontSize: { md: "14px", xs: "10px" },
                    fontWeight: "600",
                    order: { xs: 3, md: 2 }, // Move below instruction text on mobile, beside search on desktop
                  }}
                >
                  {eddContent}
                </Typography>
                <Box>
                  {/* Instruction Text */}
                  <Typography
                    sx={{
                      color: "#212121",
                      fontSize: { md: "16px", xs: "12px" },
                      fontWeight: "600",
                      width: "100%", // Full width on mobile
                      textAlign: { xs: "left", md: "left" }, // Align text to left
                      mt: { xs: "8px", md: "0" }, // Add margin-top on mobile
                      order: { xs: 2, md: 3 }, // Ensure this is below search on mobile, last on desktop
                    }}
                  >
                    Delivery Date
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: { xs: 'space-between', sm: 'center' }, // Adjust alignment for smaller screens
                  alignItems: 'center',
                  flexDirection: { xs: 'row', md: 'row' }, // Ensure horizontal layout even on mobile
                  gap: { xs: '8px', sm: '16px' }, // Reduce gap for smaller screens
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <AssignmentReturn sx={{ color: '#455F76', fontSize: { xs: 20, md: 32 } }} />
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: '12px', md: '14px' }, // Smaller font size for mobile
                    }}
                  >
                    {returnPolicy[productDetails?.return_policy_id]}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <SwapHoriz sx={{ color: '#455F76', fontSize: { xs: 20, md: 32 } }} />
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      fontSize: { xs: '12px', md: '14px' }, // Smaller font size for mobile
                    }}
                  >
                    {exchangePolicy[productDetails?.exchange_policy_id]}
                  </Typography>
                </Box>
              </Box>


              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "24px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",

                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "16px", xs: "12px" },
                    fontWeight: "500",
                    color: "#A1A1A1",
                    lineHeight: "20px",
                  }}
                >
                  Sold By:{" "}
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "12px" },
                      color: "black",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                    component="span"
                  >
                    {productDetails?.User?.businessDetails?.shop_name}
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: "16px", xs: "12px" },
                    color: "black",
                    fontWeight: "700",
                    lineHeight: "20px",
                  }}
                  component="span"
                >
                  Product Description:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: { md: "14px", xs: "10px" },
                    lineHeight: "22px",
                    color: "#6B6B6B",
                  }}
                >
                  {productDetails?.product_description}
                </Typography>
                {productDetails?.product_description?.split("\n").length > 10 && (
                  <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#455F76",
                        fontSize: { md: "14px", xs: "10px" },
                        lineHeight: "17.07px",
                      }}
                    >
                      See More
                    </Typography>
                    <KeyboardArrowDownIcon />
                  </Box>
                )}
              </Box>

              {productDetails?.OtherProductAttributes?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: "24px",
                    flexDirection: "column",
                    padding: "24px 16px",
                    borderRadius: "5px",
                    border: "1.05px solid #d9d9d9",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "12px" },
                      color: "black",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                    component="span"
                  >
                    Product Specification
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    {productDetails?.OtherProductAttributes?.slice(0, 5).map((data) => (
                      <Box
                        key={data.Attribute.name} // Add a key for better React rendering
                        sx={{
                          display: "flex",
                          alignItems: "flex-start", // Align items to the top
                          width: "100%",
                          gap: "16px", // Add gap between label and value
                          flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on desktop
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { md: "14px", xs: "12px" },
                            fontWeight: "500",
                            color: "#94969f",
                            minWidth: "120px", // Ensure consistent width for labels
                          }}
                        >
                          {data.Attribute.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { md: "14px", xs: "12px" },
                            fontWeight: "700",
                            color: "black",
                            wordBreak: "break-word", // Ensure long values don't overflow
                          }}
                        >
                          {data.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  {/* Conditionally render "See More" button if there are more than 5 attributes */}
                  {productDetails?.OtherProductAttributes?.length > 5 && (
                    <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "#455F76",
                          fontSize: { md: "14px", xs: "12px" },
                          lineHeight: "17.07px",
                        }}
                      >
                        See More
                      </Typography>
                      <KeyboardArrowDownIcon />
                    </Box>
                  )}
                </Box>
              )}
              {/* <Typography>Reviews & rating</Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Box
                  sx={{
                    backgroundColor: "#8CB89F",
                    padding: "6px 7px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5.6px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "17.07px",
                    }}
                  >
                    4.2
                  </Typography>
                  <StarIcon sx={{ color: "white", width: "13px" }} />
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "14px",
                    color: "#656565",
                  }}
                >
                  263 ratings | 30 reviews
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "11.35px",
                  flexWrap: { md: "nowrap", xs: "wrap" },
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((data) => (
                  <Box
                    component="img"
                    sx={{ width: "100px", height: "99px" }}
                    src={phon1}
                  />
                ))}
              </Box>
              <Typography>Customer Reviews (30)</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "11px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Rakhi23
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {[1, 2, 3, 4, 5].map((data) => (
                    <StarIcon sx={{ width: "20px", color: "#F2C210" }} />
                  ))}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#656565",
                    }}
                  >
                    15 Dec 2024
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Great product! Smooth performance and amazing battery life.
                  Highly recommended.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#E8EBF6",
                    padding: "8px 10px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    Variant Bought : 8GB RAM red
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#656565",
                    }}
                  >
                    Is this review helpful?
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbUpOffAltIcon sx={{ color: "#455F76" }} />
                      <Typography>8</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbDownOffAltIcon sx={{ color: "#FF5252" }} />
                      <Typography>1</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "11px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Rakhi23
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {[1, 2, 3, 4, 5].map((data) => (
                    <StarIcon sx={{ width: "20px", color: "#F2C210" }} />
                  ))}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#656565",
                    }}
                  >
                    15 Dec 2024
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Great product! Smooth performance and amazing battery life.
                  Highly recommended.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#E8EBF6",
                    padding: "8px 10px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    Variant Bought : 8GB RAM red
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#656565",
                    }}
                  >
                    Is this review helpful?
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbUpOffAltIcon sx={{ color: "#455F76" }} />
                      <Typography>8</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbDownOffAltIcon sx={{ color: "#FF5252" }} />
                      <Typography>1</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "11px",
                  flexDirection: "column",
                  padding: "24px 16px",
                  borderRadius: "5px",
                  border: "1.05px solid #d9d9d9",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  Rakhi23
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  {[1, 2, 3, 4, 5].map((data) => (
                    <StarIcon sx={{ width: "20px", color: "#F2C210" }} />
                  ))}
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#656565",
                    }}
                  >
                    15 Dec 2024
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                  }}
                >
                  Great product! Smooth performance and amazing battery life.
                  Highly recommended.
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#E8EBF6",
                    padding: "8px 10px",
                    borderRadius: "5.3px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "14px",
                    }}
                  >
                    Variant Bought : 8GB RAM red
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "11.35px",
                  }}
                >
                  {[1, 2].map((data) => (
                    <Box
                      component="img"
                      sx={{ width: "100px", height: "99px" }}
                      src={phon1}
                    />
                  ))}
                </Box>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "25px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "18px",
                      color: "#656565",
                    }}
                  >
                    Is this review helpful?
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbUpOffAltIcon sx={{ color: "#455F76" }} />
                      <Typography>8</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ThumbDownOffAltIcon sx={{ color: "#FF5252" }} />
                      <Typography>1</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                  color: "#455f76",
                  textDecoration: "Underline",
                }}
              >
                Is this review helpful?
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: { md: "20px 56px" },

          width: "100%",
        }}
      >

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: { md: "", xs: "19px 16px" },
          }}
        >


          <Typography
            sx={{
              textAlign: "start",
              fontSize: { md: "28px", xs: "16px" },
              fontWeight: "600",
              fontFamily: "Montserrat",
            }}
          >
            Similar Products
          </Typography>
          {/*<Typography
      sx={{
        color: "primary.main",
        textDecoration: "underline",
        fontSize: { md: "18.58px", xs: "12px" },
        fontWeight: "600",
        fontFamily: "montserrat",
      }}
    >
      see All
    </Typography>*/}
        </Box>

        <Box
          sx={{
            //padding: { xs: 2, md: 3 },
            //borderRadius: 2,
            //boxShadow: 1,
            marginBottom: 4,
            mx: { xs: 2, md: 0 },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              width: "100%",
              justifyContent: { md: "start", xs: "flex-start" },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
              display: "flex",
              gap: { md: "28px", xs: "8px" },
              flexWrap: { md: "nowrap", xs: "wrap" },
              overflow: { md: "scroll", xs: "none" },
              flexWrap: { md: "nowrap", xs: "wrap" },
              overflowX: { md: "auto", xs: "hidden" }, // Enable horizontal scrolling for web view
              //paddingLeft: { xs: "16px", md: "40px" }, // Add left padding for mobile
              //paddingRight: { xs: "16px", md: "40px" }, // Add right padding for mobile
              //boxSizing: "border-box", // Ensure padding is included in the width
            }}
          >
            {similarProducts?.map((data,index) => (
              <Box
                key={`${data.product_name}-${index}`}
                sx={{
                  padding: { md: "10.47px", xs: "6px" },
                  display: "flex",
                  width: { md: "248px", xs: "calc(50% - 8px)" }, // Adjusted for 2 items per row with gap
                  flexDirection: "column",
                  gap: { md: "13px", xs: "6px" },
                  borderRadius: { md: "5px", xs: "3px" },
                  border: "1px solid",
                  borderColor: "primary.light",
                  boxShadow: "0px 0px 1.07px 0px #919EAB33",
                  '&:hover': { cursor: "pointer" },
                }}
                onClick={(e) => window.open(`/product/${data.id}`, '_blank')}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    borderRadius: { md: "5px", xs: "3px" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Box
              sx={{
                backgroundColor: "primary.contrastText",
                right: "2%",
                top: "2%",
                position: "absolute",
                padding: { md: "11px 11px", xs: "4px 4px" },
                display: "flex",
                alignItems: "center",
                borderRadius: "100%",
              }}
            >
              <Box
                component="img"
                src={heart}
                alt="heart"
                sx={{
                  width: { md: "20px", xs: "15px" },
                  height: { md: "20px", xs: "15px" },
                  color: "text.secondary",
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: "customBg.white",
                right: "2%",
                top: "20%",
                position: "absolute",
                padding: { md: "11px 11px", xs: "4px 4px" },
                display: "flex",
                alignItems: "center",
                borderRadius: "100%",
              }}
            >
              <Box
                component="img"
                src={share}
                alt="share"
                sx={{
                  width: { md: "20px", xs: "15px" },
                  height: { md: "20px", xs: "15px" },
                  color: "text.secondary",
                }}
              />
            </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: { md: "245px", xs: "122px" },
                      height: { md: "245px", xs: "122px" },
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        objectFit: "contain", // Ensure the image fits within the container
                        maxWidth: "100%", // Prevent image overflow
                        maxHeight: "100%", // Prevent image overflow
                        width: "auto", // Maintain aspect ratio
                        height: "auto", // Maintain aspect ratio
                      }}
                      src={PROD_IMG_FUNC(data.User.secure_id) + data.thumbnail_image_url}
                      alt={data.title}
                    />
                  </Box>
                </Box>

                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: { md: "16px", xs: "14px" },
                    fontFamily: "montserrat",
                    color: "primary.main",
                    wordWrap: "break-word", // Ensures long words break to the next line
                    whiteSpace: "normal",  // Allows normal line wrapping
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                  }}
                >
                  {data.product_name.substr(0, 50)}
                </Typography>
                {/* <Typography
            sx={{
              fontSize: { md: "17.45px", xs: "12px" },
              fontWeight: "500",
              fontFamily: "montserrat",
            }}
          >
            {data.product_description}...
          </Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyItems: "start",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "12px" },
                      fontWeight: "700",
                      color: "success.main",
                    }}
                  >
                    Rs {data.iz_price}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "12px", xs: "8px" },
                      fontWeight: "400",
                      color: "secondary.main",
                      textDecoration: "line-through",
                    }}
                  >
                    Rs {data.tag_price}
                  </Typography>
                </Box>

                {/* <Box>
            <Typography
              sx={{
                fontSize: { md: "12px", xs: "8px" },
                fontWeight: "500",
                color: "#455F76",
              }}
            >
              Free Delivery
            </Typography>
            <Typography
              sx={{
                fontSize: { md: "14px", xs: "8px" },
                fontWeight: "700",
                color: "#8CB89F",
              }}
            >
              Bank Offer
            </Typography>
          </Box> */}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainCategory;
