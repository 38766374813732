import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";

function OrderSummary({ summary }) {
  const [pricing,setPricing]=useState({});

  useEffect(()=>{
    if(summary){
      const data ={
        gross_amount:Math.ceil(Number(summary?.gross_amount)),
        indiazona_price:Math.ceil(Number(summary?.indiazona_price)),
        net_amount:Math.ceil(Number(summary?.net_amount)),
        shipping_charges:Math.ceil(Number(summary?.shipping_charges)),
        tag_price:Math.ceil(Number(summary?.Product?.tag_price)),
        qty:Math.ceil(Number(summary?.quantity)),
      }
      setPricing(data);
    }
  },[summary]);
  

  return (
    <Paper elevation={0} sx={{ paddingX: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">Order Summary</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>MRP</Typography>
            <Typography>₹{pricing?.tag_price} × {pricing?.qty}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Discount</Typography>
            <Typography >-₹{(pricing?.tag_price*pricing?.qty)-pricing?.net_amount}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>Delivery</Typography>
            <Typography color="success.main">
              {pricing?.delivery ? `₹ ${pricing?.delivery}` : "Free"}
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" ,mb:3}}>
            <Typography fontWeight="bold">Total</Typography>
            <Typography fontWeight="bold">₹ {pricing?.net_amount}</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default OrderSummary;
