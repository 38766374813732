import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, styled } from "@mui/material";
import { get } from "../../services";
import { IN_PRODUCT, PROD_IMG_FUNC } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const DiscountBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(1),
  top: theme.spacing(1),
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius,
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    left: '4%',
    top: '4%'
  }
}));

const YouMayLike = () => {
  const [products, setProducts] = useState([]);
  const [limit, setLimit] = useState(4);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts(limit);
  }, [limit]);

  const fetchProducts = (newLimit) => {
    get(IN_PRODUCT + "get-products", { params: { highlights: "0", limit: newLimit } })
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch(console.error);
  };

  const memoizedProducts = useMemo(() =>
    products.map(data => ({
      ...data,
      discountPercentage: Math.round(((data.tag_price - data.iz_price) * 100) / data.tag_price)
    })), [products]);

  return (
    <Box sx={{
      width: "100%",
      p: { md: 6, xs: 2 },
      maxWidth: 1440,
      mx: 'auto'
    }}>
      <Box sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        mb: { md: 4, xs: 2 }
      }}>
        <Typography variant="h2" sx={{
          fontSize: { md: 28, xs: 20 },
          fontWeight: 600,
          fontFamily: "Montserrat",
        }}>
          You May Also Like
        </Typography>
        <Typography
          onClick={() => setLimit(prev => prev + 4)}
          sx={{
            color: "primary.main",
            textDecoration: "underline",
            fontSize: { md: 18.58, xs: 12 },
            fontWeight: 600,
            fontFamily: "Montserrat",
            cursor: "pointer"
          }}
        >
          See More
        </Typography>
      </Box>

      <Box sx={{
        display: 'grid',
        gap: { md: "28px", xs: "12px" },
        gridTemplateColumns: { 
          lg: "repeat(4, 1fr)", 
          md: "repeat(3, 1fr)", 
          xs: "repeat(2, 1fr)" 
        },
        width: "100%",
      }}>
        {memoizedProducts.map((data) => (
          <Box
            key={data.id}
            onClick={() => window.open(`/product/${data.id}`, "_blank")}
            sx={{
              padding: { md: "10px", xs: "6px" },
              backgroundColor: "customBg.white",
              display: "flex",
              flexDirection: "column",
              gap: { md: "13px", xs: "6px" },
              borderRadius: "8px",
              border: "1px solid",
              borderColor: "primary.light",
              boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                cursor: "pointer",
                transform: "translateY(-4px)",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
              },
            }}
          >
            <Box sx={{
              position: 'relative',
              borderRadius: "4px",
              overflow: "hidden",
              aspectRatio: "1/1",
            }}>
              {data.discount && (
                <DiscountBadge bgcolor={data.discount === "New" ? "success.main" : "secondary.main"}>
                  <Typography variant="caption" sx={{
                    color: "common.white",
                    fontWeight: 500,
                    lineHeight: 1
                  }}>
                    {data.discountPercentage}%
                  </Typography>
                </DiscountBadge>
              )}

              <Box
                component="img"
                loading="lazy"
                src={PROD_IMG_FUNC(data.User.secure_id) + data.thumbnail_image_url}
                alt={data.product_name}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
            </Box>

            <Typography variant="h3" sx={{
              fontWeight: 700,
              fontSize: { md: 19, xs: 12 },
              fontFamily: "Montserrat",
              color: "primary.main",
              minHeight: { xs: 31, md: "auto" },
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              lineHeight: "1.2",
              mt: 1
            }}>
              {data.title}
            </Typography>

            <Typography variant="body2" sx={{
              fontSize: { md: 15, xs: 10 },
              height: { md: 40, xs: 31 },
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}>
              {data.product_name}
            </Typography>

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 'auto' }}>
              <Typography variant="h4" sx={{
                fontSize: { md: 24.33, xs: 14 },
                fontWeight: 700,
                color: 'success.main'
              }}>
                ₹{data.iz_price}
              </Typography>
              <Typography variant="body2" sx={{
                fontSize: { md: 15.71, xs: 10 },
                color: 'text.secondary',
                textDecoration: 'line-through'
              }}>
                ₹{data.tag_price}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(YouMayLike);