import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import { get } from "../../services";
import { IN_PRODUCT, PROD_IMG_FUNC } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";

const ProductCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.customBg.white,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: "1px solid",
  borderColor: theme.palette.primary.light,
  boxShadow: theme.shadows[1],
  transition: "transform 0.3s, box-shadow 0.3s",
  '&:hover': {
    cursor: "pointer",
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4]
  }
}));

const DiscountBadge = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(1),
  top: theme.spacing(1),
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius,
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    left: '4%',
    top: '4%'
  }
}));

const Feature = ({ fetchKey }) => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(4);
  const navigate = useNavigate();

  useEffect(() => {
    const params = { highlights: "0", limit: 20, fetchKey: fetchKey };
    get(IN_PRODUCT + "get-products", { params })
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch(console.error);
  }, [fetchKey]);

  const handleSeeMore = () => {
    setVisibleProducts(prev => Math.min(prev + 4, products.length));
  };

  const memoizedProducts = useMemo(() => 
    products.slice(0, visibleProducts).map(data => ({
      ...data,
      discountPercentage: Math.round(((data.tag_price - data.iz_price) * 100) / data.tag_price)
    })), [products, visibleProducts]);

  return (
    <Box sx={{ 
      width: "100%", 
      p: { md: 6, xs: 2 },
      maxWidth: 1440,
      mx: 'auto'
    }}>
      <Typography variant="h2" sx={{ 
        mb: { md: 4, xs: 2 }, 
        fontSize: { md: 28, xs: 20 },
        fontWeight: 600,
        fontFamily: "Montserrat",
      }}>
        Featured Products
      </Typography>

      <Box sx={{
        display: 'grid',
        gap: { md: "28px", xs: "12px" },
          gridTemplateColumns: { 
            lg: "repeat(4, 1fr)", 
            md: "repeat(3, 1fr)", 
            xs: "repeat(2, 1fr)" 
          },
          width: "100%",
      }}>

  {products.slice(0, visibleProducts).map((data) => (
    <ProductCard
      key={data.id} onClick={() => window.open(`/product/${data.id}`, "_blank")}
      sx={{
        padding: { md: "10px", xs: "6px" },
        backgroundColor: "customBg.white",
        display: "flex",
        flexDirection: "column",
        gap: { md: "13px", xs: "6px" },
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "primary.light",
        boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
        transition: "all 0.3s ease",
        "&:hover": {
          cursor: "pointer",
          transform: "translateY(-4px)",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
        },
      }}
    >
      {data.discount && (
        <DiscountBadge
          bgcolor={data.discount === "New" ? "success.main" : "secondary.main"}
        >
          <Typography
            variant="caption"
            sx={{
              color: "common.white",
              fontWeight: 500,
              lineHeight: 1,
            }}
          >
            {data.discountPercentage}%
          </Typography>
        </DiscountBadge>
      )}

      <Box
        sx={{
          position: "relative",
          borderRadius: "4px",
          overflow: "hidden",
          aspectRatio: "1/1",
        }}
      >
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          src={PROD_IMG_FUNC(data.User.secure_id) + data.thumbnail_image_url}
          alt={data.title}
          loading="lazy"
        />
      </Box>

      <Typography
        sx={{
          fontWeight: 700,
          fontSize: { md: 19, xs: 12 },
          fontFamily: "Montserrat",
          color: "primary.main",
          minHeight: { xs: 31, md: "auto" },
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          lineHeight: "1.2",
        }}
      >
        {data.title}
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontSize: { md: 15, xs: 10 },
          height: { md: 40, xs: 31 },
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {data.product_name}
      </Typography>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: { md: 24.33, xs: 14 },
            fontWeight: 700,
            color: "success.main",
          }}
        >
          ₹{data.iz_price}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: { md: 15.71, xs: 10 },
            color: "text.secondary",
            textDecoration: "line-through",
          }}
        >
          ₹{data.tag_price}
        </Typography>
      </Box>
    </ProductCard>
  ))}
</Box>


      {visibleProducts < products.length && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            onClick={handleSeeMore}
            sx={{
              px: 4,
              py: 1.5,
              fontSize: { md: 16, xs: 14 },
              borderRadius: 1,
              bgcolor: 'primary.main',
              '&:hover': { bgcolor: 'primary.dark' }
            }}
          >
            See More Products
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(Feature);