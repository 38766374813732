import { Box, Link } from "@mui/material";
import React from "react";
import { IN_ASSETS } from "../../BaseUrl";

const data = [
  {
    imageUrl: IN_ASSETS + "homepage-banners/Banner%202.png",
    link: "/campaign",
  },
  {
    imageUrl: IN_ASSETS + "homepage-banners/Indiazona%20Website%20Banner.jpg",
    link: "/dukan",
  },
];

const SlideCard = ({ shoesInd }) => {
  return (
    <Link
      href={data[shoesInd].link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box
        src={data[shoesInd].imageUrl}
        sx={{
          width: "100%",
          '&:hover': {
            cursor: "pointer",
          },
        }}
        component="img"
      />
    </Link>
  );
};

export default SlideCard;
