import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, CardActions, IconButton, Box, Stack, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { delPayload, get, post, postFormData, put } from '../../services';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { IN_CART, PROD_IMG_FUNC } from '../../BaseUrl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const Cart = () => {
  const [refresh, setRefresh] = useState(1);
  
  const [pricing,setPricing]=useState({
    price:0,
    discount:0,
    delivery:"free",
    total:0,
  });
  const [cartItems, setCartItems] = useState([]);

  const navigate=useNavigate();


  const discounts = (item) => {
    const tagPrice=Number(item?.ProductVariant?.Product?.tag_price);
    const izPrice=Number(item?.ProductVariant?.iz_price);
    return Math.ceil(((tagPrice-izPrice)/tagPrice)*100);
    
  };

  const calculatePricing=(data)=>{
      let price=0;
      let discount=0;
      let total=0;

      data.forEach((item)=>{
        price+=(Number(item?.ProductVariant?.Product?.tag_price)*Number(item?.quantity));
        total+=(Number(item?.ProductVariant?.iz_price)*Number(item?.quantity));
      });
      discount=price-total;
      setPricing({
        price:price,
        discount:discount,
        delivery:"free",
        total:total,
      });
 
  }

  const getCartItems=()=>{
    get(IN_CART).then((res)=>{
      const {data}=res;
      setCartItems(data);
      calculatePricing(data);
    }).catch((e)=>{
      if(e.response.status===401){
        toast.warning('Please login to continue');
        navigate('/login');
      }
    })
  }

  useEffect(() => {
    getCartItems();
  }, [refresh]);



  const deleteItem = (id) => {
    // setCartItems(cartItems.filter((item) => item.id !== id));
    delPayload(IN_CART,{id}).then((res)=>{
      setRefresh(refresh+1);
    }).catch((e)=>{
      toast.error(e?.response?.data?.message);
    })
  };

  const updateQuantity = (item, amount) => {
    if(item.quantity+amount<=0)return;
    const payload={
      "id":item.id,
      "qty":item.quantity+amount
    }
    put(IN_CART+'change-qty',payload).then((res)=>{
      setRefresh(refresh+1);
    }).catch((e)=>{
      toast.error(e?.response?.data?.message);
    })


  };

  const imageLink = (item) => {
    return PROD_IMG_FUNC(item?.ProductVariant?.Product?.User?.secure_id)+item?.ProductVariant?.Product?.thumbnail_image_url
  };

  return (
    <Stack   alignItems="center"  sx={{minHeight: '100vh', backgroundColor: '#EFF8FF'}} >
        <Box sx={{backgroundColor:"#455F76",width:"100%"}} >
        <Typography variant="h5" sx={{textAlign: 'center', mt: 2, mb: 2,color:"white"}}>Cart</Typography>
        </Box>
        
        {cartItems.length===0 &&<Box>
          <Typography sx={{mt:5, fontSize:'24px',color:"text.primary"}}>
            Your cart is empty
          </Typography>
          <SentimentVeryDissatisfiedIcon  sx={{fontSize:200, color:"primary.main"}}/>
        </Box>}
    {cartItems.length>0 && <Box  sx={{width:"100%",display:"flex", justifyContent:"center", my:3}}  >
   
      <Grid container spacing={3}  sx={{ p:{xs:1,md:4},mt:2,backgroundColor: 'white',borderRadius:2,maxWidth:"1200px",marginX:2}} boxShadow={3} >
        <Grid item xs={12} md={8} sx={{ pr:{xs:3,md:2}}} >

          {cartItems?.map((item) => (
            <Card key={item.id} sx={{ mb: 2, backgroundColor: '#EFF8FF',width:"100%" }}>
              <CardContent sx={{ display: 'flex', alignItems: {sm:'center',xs:'start'}, flexDirection:{xs:'column',sm:'row'} }} >
                <Box
                  component="img"
                  src={imageLink(item)}
                  alt={item.name}
                  sx={{ width: 100, height: 100, mr: 2 }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h6">{item?.ProductVariant?.Product?.product_name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                  {item?.ProductVariant?.Product?.product_description.substr(0,40)} {item?.ProductVariant?.Product?.product_description.length>40&& "..."} 
                  </Typography>
                  <Typography variant="body2" color="success">
                    {discounts(item)}%
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Typography variant="body1" sx={{fontWeight:600, fontSize:20}} >
                      ₹{Math.ceil(Number(item?.ProductVariant?.iz_price))+" "}<Typography component="span" color="secondary.main" sx={{fontSize:12}}>
                      <s>₹{Math.ceil(Number(item?.ProductVariant?.Product?.tag_price))+" "}</s>
                    </Typography>
                    </Typography>
                    
                  </Box>
                  {/* <Typography variant="body2" color="textSecondary">
                    Delivery by {item.deliveryDate}
                  </Typography> */}
                </Box>
                <CardActions sx={{flexDirection:{sm:'column',xs:'column-reverse' },   alignItems:'start'}}>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1  }} >
                        <Button href={`/checkout?id=${item?.product_id}&v=${item?.product_variant_id}`} color="secondary" size='small' sx={{whiteSpace: "nowrap"}} disableRipple>
                            <ShoppingCartCheckoutIcon /> Buy only this
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <Button onClick={() => deleteItem(item.id)} color="error" disableRipple size='small'>
                            <DeleteIcon /> Delete
                        </Button>
                    </Box>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2,border: '1px solid #ccc',backgroundColor:'white',width:"fit-content",marginLeft:'auto',mr:1,mt:3 }}>
                    <Button onClick={() => updateQuantity(item, -1)}>-</Button>
                    <Typography  sx={{ my: 1, fontSize:12 }}>
                        {item.quantity}
                    </Typography>
                    <Button onClick={() => updateQuantity(item, 1)}>+</Button>
                    </Box>
                </CardActions>
              </CardContent>
              
            </Card>
          ))}
        </Grid>
        <Grid item xs={12} md={4} sx={{ pr:{xs:3,md:2}, mb:3}} >
          <Card sx={{height:"330px"}} >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Order Summary
              </Typography>
              <Box sx={{ mb: 3,display:"flex",gap:2,flexDirection:"column" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1">Price</Typography>
                  <Typography variant="body1">₹{pricing?.price}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1">Discount</Typography>
                  <Typography variant="body1" color="success.main">
                  ₹{pricing.discount}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1">Delivery</Typography>
                  <Typography variant="body1" color="success.main">
                    FREE
                  </Typography>
                </Box>
      
                <hr />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Total</Typography>
                  <Typography variant="h6">
                    {pricing?.total}
                  </Typography>
                </Box>
              </Box>
              <Button variant="contained" fullWidth sx={{ bgcolor: '#FF944E', color: '#fff' }} href='/checkout?c=1' >
                Checkout
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
}
    </Stack>
  );
};

export default Cart;