import { Box, Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

const SubheaderCommon = () => {
    const data = [
      { title: "Electronic", slug: 'electronic' },
      { title: "Home Appliances",  slug: 'home-appliances' },
      { title: "Organic Superfoods",  slug: 'organic-superfoods' },
      { title: "Sarees", slug: 'sarees' },
      { title: "Ethnic Wear",  slug: 'ethnic-wear' },
      { title: "Baby Fashion",  slug: 'baby-fashion' },
      { title: "Kitchen Appliances", slug: 'kitchen-appliances' },
      { title: "Handmade Items", slug: 'handmade-items' },
      { title: "Beauty Appliances", slug: 'beauty-appliances' },
    ];
  return (
    <Box
        sx={{
          padding: { md: "16px 73px", xs: "19px 16px" },
          "&::-webkit-scrollbar": {
            display: "none",
          },

          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          overflow: { md: "hidden", xs: "scroll" },
          backgroundColor: "#455F76",
          display: "flex",
          alignItems: "center",
          gap: { md: "48px", xs: "28px" },
        }}
      >
        {data.map((data) => (
          <Typography to={`/category/${data.slug}`} key={data.title}
          component={Link}
            sx={{
              fontSize: { md: "16px", xs: "10px" },
              fontFamily: "Montserrat",
              fontWeight: { md: "600", xs: "500" },
              color: "white",
              textDecoration: "none",
            }}
          >
            {data.title}
          </Typography>
        ))}
      </Box> 
  )
}

export default SubheaderCommon
