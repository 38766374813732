import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IN_PRODUCT, PROD_IMG_FUNC } from '../../BaseUrl'
import { useNavigate } from 'react-router-dom';
import { get } from '../../services';

const DukanProducts = () => {
      const [products, setProducts] = useState([]);
        const navigate=useNavigate();
      
        useEffect(() => {
          const params = {
            highlights: "0",
            limit: 20,
          };
          get(IN_PRODUCT + "get-products", { params })
            .then((res) => {
              const { data } = res;
              console.log(data);
              setProducts(data.data);
              console.log(
                PROD_IMG_FUNC(data.data[1].User.secure_id) +
                  data.data[1].thumbnail_image_url
              );
            })
            .catch((e) => {
              console.log(e);
            });
        }, []);
    
  return (
    <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
            p:4,
            marginY:4,
        }}
    >

    
    <Box
        sx={{
        display: "grid",
        gap: { md: "28px", xs: "8px" },
        gridTemplateColumns: { md: "1fr 1fr 1fr 1fr", xs: "1fr 1fr" },
        width: "100%",
        }}
        >
          {products?.map((data) => (
            <Box
              key={data.title}
              sx={{
                padding: { md: "10.47px", xs: "6px" },
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                gap: { md: "13px", xs: "6px" },
                borderRadius: { md: "5px", xs: "3px" },
                border: "1px solid",
                borderColor: "primary.light",
                boxShadow: "0px 0px 1.07px 0px #919EAB33",
                '&:hover':{
                  cursor:"pointer",
                }
              }}

              onClick={(e) => window.open(`/product/${data.id}`, '_blank')}
            >
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  borderRadius: { md: "5px", xs: "3px" },
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "background.default",
                  width:"20vw"
                }}
              >
                {data.discount && (
                  <Box
                    sx={{
                      backgroundColor:
                        data.discount === "New"
                          ? "success.main"
                          : "secondary.main",
                      left: { md: "4%", xs: "2px" },
                      top: { md: "4%", xs: "2px" },
                      position: "absolute",
                      padding: { md: "4px 13px", xs: "2px 7px" },
                      borderRadius: { md: "4px", xs: "2px" },
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "Montserrat", // Capitalized for consistency
                        color: "customBg.white",
                      }}
                    >
                      {((data.tag_price - data.iz_price) * 100) /
                        data.tag_price}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: { md: "300px", xs: "122px" },
                    overflow: "hidden",
                    backgroundColor:"white",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      // objectFit: "cover",
                      // height: { md: "245px", xs: "122px" },
                      height:"100%"
                    }}
                    src={
                      PROD_IMG_FUNC(data.User.secure_id) +
                      data.thumbnail_image_url
                    }
                    alt={data.title}
                  />
                </Box>
              </Box>

              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: { md: "19.2px", xs: "14px" },
                  fontFamily: "montserrat",
                  color: "primary.main",
                }}
              >
                {data.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "17.45px", xs: "12px" },
                  fontWeight: "500",
                  fontFamily: "montserrat",
                }}
              >
                {data.product_name}...
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: { md: "10px", xs: "5px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "24.33px", xs: "12px" },
                    fontWeight: "700",
                    color: "success.main",
                    fontFamily: "Montserrat",
                  }}
                >
                  Rs {data.iz_price}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: "15.71px", xs: "8px" },
                    fontWeight: "400",
                    color: "secondary.main",
                    fontFamily: "Montserrat",
                    textDecoration: "line-through",
                  }}
                >
                  Rs {data.tag_price}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        </Stack>
  )
}

export default DukanProducts
