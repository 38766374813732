
export const IN_URL = "https://seashell-app-2jxuz.ondigitalocean.app/api/v1/app/";
export const BASE_URL = "https://seashell-app-2jxuz.ondigitalocean.app/api/v1/";



// export const BASE_URL = "http://localhost:8000/api/v1/"
// export const IN_URL = "http://localhost:8000/api/v1/app/";


export const IN_IMG = "https://indiazona-assets.blr1.cdn.digitaloceanspaces.com/vendors/";


export const IN_ASSETS = "https://indiazona-assets.blr1.cdn.digitaloceanspaces.com/assets/images-prod/";


export const IN_PRODUCT = `${IN_URL}product/`;
export const IZ_USER = `${IN_URL}user/`;
export const IN_ORDERS = `${IN_URL}orders/`;
export const IN_CART = `${IN_URL}cart/`;






export const  PROD_IMG_FUNC = (code)=> {
    return `${IN_IMG}${code}/products/images/`
    } 
// export const IN_URL = "http://192.168.1.140:8000/api/v1";

export const IZ_ASSETS = "https://indiazona-assets.blr1.cdn.digitaloceanspaces.com/assets/images-prod/";
