import { Autocomplete, Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { get, put } from "../../services";
import { BASE_URL, IZ_USER } from "../../BaseUrl";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { phone_number, postal_code, required } from "../../utils/validations";

const CommonAddressModal = ({open,editAddress,setShowForm,setRefresh,setEditAddress}) => {
    const [stateOption, setStateOption] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ctyErr, setCtyErr] = useState(false);
    const [stErr, setStErr] = useState(false);

    const [stateValue, setStateValue] = useState(null);
    const [cityValue, setCityValue] = useState(null);


    useEffect(()=>{
        get(BASE_URL+'/states').then((res)=>{
            const {data}=res;
            setStateOption(data.data);
            // console.log(stateOption);
        }).catch((e)=>{
            // toast.error("Something went wrong please try again later");
            console.error(e);
        })
    
      },[]);
      useEffect(()=>{
        if(stateValue) {
            get(BASE_URL+`/cities/${stateValue.id}`).then((res)=>{
                const {data}=res;
                setCityOption(data.data);
    
            }).catch((e)=>{
                // toast.error("Something went wrong please try again later");
                console.error(e);
            })
        }
      },[stateValue]);

      const [address, setAddress] = useState({
          name: '',
          mobile: '',
          pincode: '',
          locality: '',
          areaStreet: '',
          city: '',
          state: '',
          landmark: '',
          Altmobile: '',
          addressType: '', // Default to an empty string
        });
        
        const handleInputChange = (e) => {
          const { name, value } = e.target;
          setAddress((prev) => ({ ...prev, [name]: value }));
        };
      
        // const handleSave = () => {
        //   if (Object.keys(address).length > 0) {
        //     if (editIndex !== null) {
        //       // Update existing address
        //       setAddresses((prev) =>
        //         prev.map((addr, index) => (index === editIndex ? address : addr))
        //       );
        //     } else {
        //       // Add new address
        //       setAddresses((prev) => [...prev, address]);
        //     }
        //   }
        //   setShowForm(false);
        //   setAddress({});
        //   setEditIndex(null);
        // };
      
        const handleCancel = () => {
          setShowForm(false);
          setEditAddress(null);
          setAddress({});
        //   setEditIndex(null);
        };
      
        // const handleEdit = (index) => {
        //   setAddress(addresses[index]); // Load the selected address into the form
        // //   setEditIndex(index); // Set the edit index
        //   setShowForm(true); // Open the form modal
        // };
      
        
      
      const onSubmit=()=>{
        setLoading(true);
      if(!stateValue){
        setLoading(false);
          setStErr(true);
          return;
      }
      if(!cityValue){
        setLoading(false);
          setCtyErr(true);
          return;
      }
      const payload={
          "manager_name":values.whManager,
          "phone":values.whContact,
          "address":values.flatAndStreet,
          "country_id":'101',
          "state_id":stateValue.id,
          "city_id":cityValue.id,
          "postal_code":values.pinCode,
          "landmark":values.landmark,
          "id":values.id,
      }
      
        put(IZ_USER+'update-address',payload).then((res)=>{
          const {data}=res;
          setLoading(false);
          setRefresh((prev)=>prev+1);
          handleCancel();
        }).catch((e)=>{
          setLoading(false);
          toast.error("Please create an account or login")
          window.location.href = "/login";
          
          console.log(e);
        })
      
      
      
      }

      const pickupSchema = Yup.object({
        whManager:required,
        flatAndStreet:required,
        whContact: phone_number,
        flatAndStreet:required,
        landmark:required,
        pinCode:postal_code,
        addressType:required,
    
    });
      
        const {values,touched,handleBlur,handleChange,errors,setFieldValue,handleSubmit,resetForm}=useFormik({
          initialValues:{
              whManager:"",
              whContact:"",
              flatAndStreet:"",
              landmark:"",
              pinCode:"",
              id:"",
              addressType:"home",
              
          },
          validationSchema: pickupSchema,
          onSubmit,
      
        });


        useEffect(()=>{
            

            if (editAddress) {
                setFieldValue("whManager", editAddress.manager_name);
                setFieldValue("whContact", editAddress.phone);
                setFieldValue("flatAndStreet", editAddress.address);
                setFieldValue("landmark", editAddress.landmark);
                setFieldValue("pinCode", editAddress.postal_code);
                setFieldValue("id", editAddress.id);
                setFieldValue("addressType", editAddress.address_type ? editAddress.address_type : "home");
                setStateValue(editAddress.state);
                setCityValue(editAddress.city);
              } else {
                // Clear all values when editAddress is null
                setFieldValue("whManager", "");
                setFieldValue("whContact", "");
                setFieldValue("flatAndStreet", "");
                setFieldValue("landmark", "");
                setFieldValue("pinCode", "");
                setFieldValue("id", "");
                setFieldValue("addressType", "home"); // Default value
                setStateValue(null);
                setCityValue(null);
              }
        },[editAddress]);

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby="add-address-modal"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "24px",
          width: "90%",
          maxWidth: "848px",
          maxHeight: "650px", // Limit modal height to viewport height
          overflowY: "auto", // Enable scrolling for overflow
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            marginBottom: "16px",
            textAlign: "center",
          }}
        >
          {editAddress !== null
            ? "Edit Delivery Address"
            : "Add New Delivery Address"}
        </Typography>

        {/* Address Form */}

        <form onSubmit={handleSubmit} autoComplete="off">
          {/* Row 1: account number------------------------------------------------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {/* Account Number */}
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Name
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="whManager"
                value={values.whManager}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.whManager && touched.whManager}
                helperText={
                  errors.whManager && touched.whManager ? errors.whManager : ""
                }
              />
            </Stack>

            {/* confirm Account Number */}
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Phone Number
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="whContact"
                value={values.whContact}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.whContact && touched.whContact}
                helperText={
                  errors.whContact && touched.whContact ? errors.whContact : ""
                }
              />
            </Stack>
          </Stack>

          {/* Row 2:Beneficiary Name Bank Name------------------------------------------------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {/* Account Number */}
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Flat/Street name{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="flatAndStreet"
                value={values.flatAndStreet}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.flatAndStreet && touched.flatAndStreet}
                helperText={
                  errors.flatAndStreet && touched.flatAndStreet
                    ? errors.flatAndStreet
                    : ""
                }
              />
            </Stack>

            {/* confirm Account Number */}
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Landmark{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="landmark"
                value={values.landmark}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.landmark && touched.landmark}
                helperText={
                  errors.landmark && touched.landmark ? errors.landmark : ""
                }
              />
            </Stack>
          </Stack>
          {/* Row 1: IFSC Code and Bank Branch------------------------------------------------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {/* Account Number */}
            <Stack spacing={1} sx={{ flex: 1, mt: 2.5 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Country{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              {/* <Autocomplete
                    options={countryOption}
                    getOptionLabel={(option) => option.name} // Use the 'label' property for display
                    value={countryValue}
                    onChange={(event, newValue) => {
                        setCtyErr(false);
                        setCountryValue(newValue)}}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select an option"
                        placeholder="Search..."
                    />
                    )}
                    fullWidth
                    disablePortal
                /> */}
              <TextField
                fullWidth
                variant="outlined"
                name="landmark"
                value="India"
                disabled
              />
            </Stack>

            {/* confirm Account Number */}
            <Stack spacing={1} sx={{ flex: 1, mt: 2.5 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                State{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={stateOption}
                getOptionLabel={(option) => option.name} // Use the 'label' property for display
                value={stateValue}
                onChange={(event, newValue) => {
                  setStErr(false);
                  setStateValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an option"
                    placeholder="Search..."
                  />
                )}
                fullWidth
                disablePortal
              />
              {stErr && (
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "#d32f2f" }}
                >
                  State is required
                </Typography>
              )}
            </Stack>
          </Stack>

          {/* Row 2:Beneficiary Name Bank Name------------------------------------------------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {/* Account Number */}
            <Stack spacing={1} sx={{ flex: 1, mt: 2.5 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                City{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Autocomplete
                options={cityOption}
                getOptionLabel={(option) => option.name} // Use the 'label' property for display
                value={cityValue}
                onChange={(event, newValue) => {
                  setCtyErr(false);
                  setCityValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an option"
                    placeholder="Search..."
                  />
                )}
                fullWidth
                disablePortal
              />
              {ctyErr && (
                <Typography
                  variant="body1"
                  sx={{ fontSize: "12px", color: "#d32f2f" }}
                >
                  City is required
                </Typography>
              )}
            </Stack>

            {/* confirm Account Number */}
            <Stack spacing={1} sx={{ flex: 1 }}>
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Pin Code{" "}
                <Typography component="span" sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="pinCode"
                value={values.pinCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.pinCode && touched.pinCode}
                helperText={
                  errors.pinCode && touched.pinCode ? errors.pinCode : ""
                }
              />
            </Stack>
          </Stack>
          <Stack direction="row" fullWidth sx={{mt:1}}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Address Type</FormLabel>
                    <RadioGroup row name="addressType" value={values.addressType} onChange={handleChange}>
                    <FormControlLabel value="home" control={<Radio />} label="Home" />
                    <FormControlLabel value="office" control={<Radio />} label="Office" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </FormControl>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "16px",
              "@media (max-width: 600px)": {
                flexDirection: "column",
                gap: "12px",
              },
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                width: "267px",
                height: "56px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "20px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // onClick={handleSave}
              color="secondary"
              type="submit"
              sx={{
                width: "267px",
                height: "56px",
                fontFamily: "Montserrat",
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "20px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: "#FFFFFF" }} />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </form>

        {/* Action Buttons */}
      </Box>
    </Modal>
  );
};

export default CommonAddressModal;
