import React from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Share2, Facebook, Copy, MessageSquare, Mail, Instagram, Send} from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareButton = ({ shareUrl, productName }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWhatsAppShare = () => {
    const url = `https://wa.me/?text=${encodeURIComponent(
      `Check out ${productName} - ${shareUrl}`
    )}`;
    window.open(url, "_blank");
  };

  const handleFacebookShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, "_blank");
  };

  const handleTelegramShare = () => {
    const url = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(
      `Check out ${productName}`
    )}`;
    window.open(url, "_blank");
  };

  const handleInstagramShare = () => {
    alert("Instagram sharing requires deep linking in-app, so this will open Instagram.");
    // Placeholder since Instagram doesn't provide a direct web share API.
    window.open("https://www.instagram.com", "_blank");
  };

  const handleGmailShare = () => {
    const url = `mailto:?subject=${encodeURIComponent(
      `Check out : ${productName}`
    )}&body=${encodeURIComponent(shareUrl)}`;
    window.open(url, "_self");
  };

  const handleCopyLink = () => {
    alert("Link copied to clipboard!");
  };

  return (
    <>
      <Tooltip title="Share">
        <IconButton
          onClick={handleClick}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            "&:hover": { bgcolor: "white" },
          }}
        >
          <Share2 size={24} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem onClick={handleWhatsAppShare}>
          <MessageSquare size={20} style={{ marginRight: 8 }} />
          WhatsApp
        </MenuItem>
        <MenuItem onClick={handleFacebookShare}>
          <Facebook size={20} style={{ marginRight: 8 }} />
          Facebook
        </MenuItem>
        <MenuItem onClick={handleTelegramShare}>
          <Send size={20} style={{ marginRight: 8 }} />
          Telegram
        </MenuItem>
        <MenuItem onClick={handleInstagramShare}>
          <Instagram size={20} style={{ marginRight: 8 }} />
          Instagram
        </MenuItem>
        <MenuItem onClick={handleGmailShare}>
          <Mail size={20} style={{ marginRight: 8 }} />
          Gmail
        </MenuItem>
        <CopyToClipboard text={shareUrl} onCopy={handleCopyLink}>
          <MenuItem>
            <Copy size={20} style={{ marginRight: 8 }} />
            Copy Link
          </MenuItem>
        </CopyToClipboard>
      </Menu>
    </>
  );
};

export default ShareButton;
