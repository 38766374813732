import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { PlayCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SingleVideo from "./SingleVideo";
import { get } from "../../services";
import { IN_URL } from "../../BaseUrl";
import { useDispatch } from "react-redux";
import { setTrendsInfo, setTrends } from "../../store/slices/trendsSlice";

const Trend = ({ page }) => {
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    get(IN_URL + "videos", { params: { limit: 8, page: page } })
      .then((res) => setVideos(res.data.data))
      .catch(console.error);
  }, [page]);

  const handleVideoSelect = (video,index) => {
    dispatch(setTrends(videos)); // Set all videos to redux store
    dispatch(setTrendsInfo({
        page:page,
        index:index,
    })); // Set selected video to redux store
    navigate("/trends", { state: { video } }); // Navigate with video data
  };

  return (
    <Box
      sx={{
        backgroundColor: "#E9FFF0",
        padding: { md: "35px 50px", xs: "12px" },
        display: "flex",
        alignItems: "start",
        gap: { md: "14px", xs: "12px" },
        width: "100%",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Header />
      <VideoList videos={videos} onSelect={handleVideoSelect} />
    </Box>
  );
};

const Header = () => (
  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", mb: 3 }}>
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <PlayCircle color="secondary" sx={{ fontSize: { md: 33, xs: 24 } }} />
      <Typography
        variant="h4"
        sx={{ fontWeight: 600, fontSize: { md: "28px", xs: "20px" } }}
      >
        Trends{" "}
        <Typography component="span" color="success.main">
          (Swipe & Buy)
        </Typography>
      </Typography>
    </Box>
  </Box>
);

const VideoList = ({ videos, onSelect }) => (
  <Box
    sx={{
      overflowX: "scroll",
      "::-webkit-scrollbar": { display: "none" },
      width: "100%",
      mx: "auto",
      px: 0,
    }}
  >
    <Box
      sx={{
        display: "flex",
        gap: { md: 6, xs: 0.5 },
        width: "max-content",
      }}
    >
      {videos.map((vid,index) => (
        <Box
          key={vid.id}
          sx={{
            width: { md: "15vw", xs: "30vw" },
            flexShrink: 0,
          }}
        >
          <Box sx={{ position: "relative", borderRadius: 2, overflow: "hidden" }}>
            <SingleVideo
              vid={vid}
              ratio="9/16"
              radius={8}
              onEnterImmersedView={() => onSelect(vid,index)}
              autoPlay
              showShare
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              mt: 1,
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: { md: "16px", xs: "10px" },
            }}
          >
            {vid.title}
          </Typography>
        </Box>
      ))}
    </Box>
  </Box>
);

export default Trend;
