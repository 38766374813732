import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null, // { id, name, email, etc. }
  isAuthenticated: false,
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setUser, clearUser } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
