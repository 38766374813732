import React, { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Typography } from "@mui/material";
import { get } from "../../services";
import { IN_PRODUCT, IN_URL, PROD_IMG_FUNC } from "../../BaseUrl";
import SingleVideo from "./SingleVideo";

const ClotheSection = () => {
  const [youMayLike, setYouMayLike] = useState();
  const [highlight, setHighlight] = useState();
  const [trend, setTrend] = useState();
  const [currentIndex, setCurrentIndex] = useState(null); // State for immersed view
  const [currentSection, setCurrentSection] = useState(null); // Track which section is active
  const [productData, setProductData] = useState([
    {
      id: 1,
      title: "You may also like",
      trend: false,
      detail: [],
    },
    {
      id: 2,
      title: "Keep Shopping with us",
      trend: false,
      detail: [],
    },
    {
      id: 3,
      title: "Explore videos to know Product",
      trend: true,
      detail: [],
    },
  ]);

  // Fetch highlights
  useEffect(() => {
    const params = {
      highlights: "1",
      limit: 4,
    };
    get(IN_PRODUCT + "get-products", { params })
      .then((res) => {
        const { data } = res;
        setHighlight(data.data);
        const temp = [...productData];
        temp[0].detail = data.data;
        setProductData(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // Fetch daily deals
  useEffect(() => {
    const params = {
      is_daily_deals: "1",
      limit: 4,
    };
    get(IN_PRODUCT + "get-products", { params })
      .then((res) => {
        const { data } = res;
        setYouMayLike(data.data);
        const temp = [...productData];
        temp[1].detail = data.data;
        setProductData(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // Fetch trending videos
  useEffect(() => {
    const params = {
      limit: 4,
    };
    get(IN_URL + "videos", { params })
      .then((res) => {
        const { data } = res;
        setTrend(data.data);
        const temp = [...productData];
        temp[2].detail = data.data;
        setProductData(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // Handle entering immersed view
  const handleEnterImmersedView = (sectionIndex, videoIndex) => {
    setCurrentSection(sectionIndex); // Track which section is active
    setCurrentIndex(videoIndex); // Track which video is active
  };

  // Handle closing immersed view
  const handleCloseImmersedView = () => {
    setCurrentIndex(null);
    setCurrentSection(null);
  };

  return (
    <Box sx={{ width: "100%", padding: { md: "0px 56px", xs: "0px 16px" } }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: { md: "nowrap", xs: "wrap" },
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          // border:"2px solid red",
        }}
      >
        {productData?.map((data, sectionIndex) => (
          <Box
            key={data.title}
            sx={{
              width: "100%",
              position: "relative",
              padding: { md: "20px 24px", xs: "14px" },
              gap: "15px",
              backgroundColor: "primary.contrastText",
              boxShadow: "0px 7.39px 14.78px -2.46px rgba(145, 158, 171, 0.12)",
              height:{
                md: "670px",
              },
              
              mb: 3,
            }}
          >
            {data.trend && (
              <Box
                sx={{
                  backgroundColor: "success.main",
                  width: "fit-content",
                  padding: "4px 7px",
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  fontFamily: "Montserrat",
                  
                }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "primary.contrastText",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  Trend's
                </Box>
              </Box>
            )}
            <Typography sx={{ marginBottom: "18px" }}>{data.title}</Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "15px",
              }}
            >
              {data.detail.map((detail, videoIndex) => (
                <Box
                  key={detail?.product_name}
                  sx={{
                    display: "flex",
                    position: "relative",
                    gap: { md: "15px", xs: "13px" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  {data.trend && (
                    <PlayArrowIcon
                      sx={{
                        position: "absolute",
                        top: "30%",
                        left: "40%",
                        color: "primary.contrastText",
                      }}
                    />
                  )}
                  {data.trend ? (
                    <Box sx={{ width: 150 }}>
                      <SingleVideo
                        vid={detail}
                        ratio={"4/5"}
                        radius={1}
                        onEnterImmersedView={() =>
                          handleEnterImmersedView(sectionIndex, videoIndex)
                        }
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        // objectFit: "cover",
                        // height: { md: "210px", xs: "200px" },
                        maxHeight: { md: "210px", xs: "200px" },
                        width: { xs: "150px" },
                        // aspectRatio: "9/16",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                      component="img"
                      src={
                        PROD_IMG_FUNC(detail?.User?.secure_id) +
                        detail?.thumbnail_image_url
                      }
                      onClick={(e) =>
                        window.open(`/product/${detail.id}`, "_blank")
                      }
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "14px" },
                      fontWeight: "500px",
                    }}
                  >
                    {detail?.product_name?.substr(0, 50) ||
                      detail?.title?.substr(0, 20)}
                    ...
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>

      {/* Render Immersed View */}
      {currentIndex !== null && currentSection !== null && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            zIndex: 1000,
          }}
        >
          <SingleVideo
            vid={productData[currentSection].detail[currentIndex]}
            isImmersed
            ratio={"16/9"}
            radius={0}
            autoPlay
            onEnterImmersedView={handleCloseImmersedView}
          />
        </Box>
      )}
    </Box>
  );
};

export default ClotheSection;
