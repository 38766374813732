import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton, Button, Collapse, Typography, Stack } from "@mui/material";
import { PlayArrow, Pause, ExpandMore, ExpandLess } from "@mui/icons-material";
import { PROD_IMG_FUNC } from "../../BaseUrl";
import ShareButton from "../common/ShareButton";
import ShareIcon from "@mui/icons-material/Share"; // Import the Share icon
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const SingleVideo = ({
  vid,
  ratio = 9 / 16,
  radius,
  onEnterImmersedView,
  onPlayPause,
  autoPlay,
  isImmersed = false,
  showShare = false,
  onPrev,
  onNext,
}) => {
  const [isPlaying, setIsPlaying] = useState(autoPlay || false);
  const videoRef = useRef(null);
  const [expanded,setExpanded]=useState(false);

  const handlePlayPause = (e) => {
    e.stopPropagation();
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(console.error);
      }
      setIsPlaying(!isPlaying);
      onPlayPause?.(!isPlaying);
    }
  };

  useEffect(() => {
    if (autoPlay && videoRef.current) {
      videoRef.current.play().catch(() => setIsPlaying(false));
    }
  }, [autoPlay]);

  if (!vid || !vid.User) return null;
  

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        bgcolor: "black",
        overflow: "hidden",
        borderRadius: radius || 4,
        aspectRatio: ratio || "9/16",
        boxShadow: 3,
        "&:hover": {
          cursor: "pointer",
          "& .play-pause-button": {
            opacity: 1,
          },
        },
      }}
      onClick={onEnterImmersedView}
    >
      <Box  sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}> 
        <video
          ref={videoRef}
          src={PROD_IMG_FUNC(vid.User.secure_id) + vid.video_url}
          style={{
            // width: "100%",
            height: "100%",
            objectFit: "cover",
            aspectRatio: ratio,
          }}
          muted
          loop
          playsInline
          autoPlay={true}
        />
      </Box>
{isImmersed&&(<IconButton
        onClick={onPrev}
        sx={{
          position: "absolute",
          left: "30%",
          top: "50%",
          transform: "translateY(-50%)",
          bgcolor: "#8CB89F",
          color: "white",
          // "&:hover": { bgcolor: "rgba(0, 0, 0, 0.8)" },
          display:{
            xs:"none",
            md:"flex"
          }
        }}
      >
        <ArrowBackIos />
      </IconButton>)}
      

      {/* Right (Next) Button */}
      {isImmersed&&(<IconButton
        onClick={onNext}
  
        sx={{
          position: "absolute",
          right:"30%",
          top: "50%",
          transform: "translateY(-50%)",
          bgcolor: "#8CB89F",
          color: "white",
          // "&:hover": { bgcolor: "rgba(0, 0, 0, 0.8)" },
          display:{
            xs:"none",
            md:"flex"
          }
        }}
      >
        <ArrowForwardIos />
      </IconButton>)}

      {showShare && (
        <Box
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={(e) => e.stopPropagation()}
        >
          <ShareButton
            shareUrl={`https://indiazona.in/trends`}
            productName={vid.title}
            shareIcon={
              <IconButton
                sx={{
                  bgcolor: "rgba(255,255,255,0.8)",
                  "&:hover": { bgcolor: "white" },
                }}
              >
                <ShareIcon sx={{ color: "black", fontSize: 24 }} />{" "}
                {/* Material-UI Share icon */}
              </IconButton>
            }
          />
        </Box>
      )}

      {isImmersed && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: "16px",
            color: "white",
            display: "flex",
            flexDirection: "column",
            height: expanded ? "75%" : "auto", // Limit height to 75% in expanded state
            maxHeight: "75%", // Ensure it doesn't exceed 75% of the screen
          }}
        >
          {/* Fixed Product Title */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: "0.8rem",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: expanded ? "unset" : 2,
              flexShrink: 0, // Prevent title from shrinking
            }}
          >
            {vid.title}
          </Typography>

          {/* Scrollable Description */}
          <Box
            sx={{
              flexGrow: 1, // Take up remaining space
              overflowY: "auto", // Enable vertical scrolling
              mt: 1,
            }}
          >
            <Collapse in={expanded} collapsedSize={0}>
              <Typography variant="body2" sx={{ color: "#BABABA" }}>
                {vid.description}
              </Typography>
            </Collapse>
          </Box>

          {/* Fixed Buttons */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              mt: 2,
              justifyContent: "space-between",
              flexShrink: 0, // Prevent buttons from shrinking
            }}
          >
            <Button
              variant="contained"
              sx={{
                flex: 1,
                bgcolor: "#8CB89F",
                "&:hover": { bgcolor: "#7CA78F" },
              }}
              onClick={() =>
                window.open(`/product/${vid.product_id}`, "_blank")
              }
            >
              Buy Now
            </Button>

            <Button
              variant="outlined"
              sx={{
                flex: 1,
                borderColor: "#8CB89F",
                color: "#8CB89F",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
              {expanded ? "See Less" : "See More"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SingleVideo;
