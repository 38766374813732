import React, { useEffect, useState } from 'react'
import YouMayLike from '../components/HomePage/YouMayLike'
import Trend from '../components/HomePage/Trend'
import BackGroundBanner from '../components/Dukan/BackGroundBanner'
import Cards from '../components/Dukan/Cards'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from '../services'
import { IN_PRODUCT, PROD_IMG_FUNC } from '../BaseUrl'
import CategoryProducts from '../components/category/CategoryProducts'
import SubheaderCommon from '../components/common/SubheaderCommon'



const Category = () => {


  const data = [
    { title: "Electronic",slug:'electronic',params:{'subcategory':'Electronics'}},
    { title: "Home Appliances",slug:'home-appliances',params:{'subcategory':'Electronics - Home Appliances'} },
    { title: "Organic Superfoods",slug:'organic-superfoods',params:{'category':'Grocery & Food'}},
    { title: "Grocery & Food",slug:'grocery-and-food',params:{'category':'Grocery & Food'}},
    { title: "Clothing, Fashion & Accessories",slug:'clothing',params:{'category':'Clothing'}},
    { title: "Sarees", slug:'sarees',params:{'subcategory':'Apparel - Sarees and Dress Materials'}},
    { title: "Ethnic Wear",slug:'ethnic-wear',params:{'subcategory':'Apparel - Ethnic wear'}},
    { title: "Baby Fashion",slug:'baby-fashion',params:{'subcategory':'Apparel - Baby'}},
    { title: "Kitchen Appliances",slug:'kitchen-appliances',params:{'subcategory':'Kitchen  Appliances'}},
    { title: "Handmade Items",slug:'handmade-items',params:{'category':'Handicrafts'}},
    { title: "Beauty Appliances",slug:'beauty-appliances',params:{'subcategory':'Personal Care Appliances (Grooming & Styling)'}},
  ];  

  const {slug}=useParams();


  const [products, setProducts] = useState([]);
  const [singleData, setSingleData] = useState(null);
  const navigate=useNavigate();
   
  useEffect(()=>{

    const category=data.find((val)=>val.slug===slug);
    setSingleData(category);

    if(category){

      const searchParam=category.params

      const params={
        ...searchParam,
        'limit':100,
      }
      get(IN_PRODUCT+'get-category-products',{params}).then((res)=>{
        const {data}=res;
        setProducts(data.data);
      }).catch((e)=>{
        console.log(e);
      });

    }
      
  },[slug]);

  return (
   <>
   <SubheaderCommon />
   <Cards categoryInfo={singleData} />
   <CategoryProducts products={products}/>
   <Trend/>
   <YouMayLike/>
   {/* <BackGroundBanner/> */}
   </>
  )
}

export default Category;