// src/components/VideoList.js
import React from "react";
// import Video from "./Video";
import { Box, Container, Typography } from "@mui/material";
import Video from "./Video";

const videos = [
  "https://www.w3schools.com/html/mov_bbb.mp4",
  "https://www.w3schools.com/html/movie.mp4",
  // Add more video URLs here
];

const VideoList = () => {
  return (
    <Container>
      <Typography variant="h4" sx={{ my: 4, textAlign: "center" }}>
        YouTube Clone
      </Typography>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          gap: 2,
          py: 2,
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
          },
        }}
      >
        {videos.map((src, index) => (
          <Video key={index} src={src} />
        ))}
      </Box>
    </Container>
  );
};

export default VideoList;