import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Custom styled StepConnector with transition
const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: "#ccc", // Default gray
    borderTopWidth: 3,
    transition: "border-color 0.5s ease", // Smooth transition
  },
  [`&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line`]: {
    borderColor: "#8CB89F", // Green for active and completed steps
  },
}));



// Custom Step Icon Component with Step Number
const CustomStepIcon = ({ active, completed, icon,isCancelled }) => {
  const stepStyle = {
    backgroundColor: completed ? "#8CB89F" : isCancelled&&icon===2 ? "#FF5252" : "#ccc",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontFamily: "Roboto",
    transition: "background 1s ease",

  };

  return <Box style={stepStyle} sx={{ width: 32, height: 32 }}>{icon}</Box>;
};

// Steps array
// const stepsArr = ["Order Confirmed", "Shipped", "In Transit", "Out For Delivery", "Delivered"];

const StepperProgress = ({ step,stepsArr,isCancelled }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ width: "100%", px: { xs: 0.2, md: 3 }, py: 3 }}>
      <Stepper
        activeStep={step}
        alternativeLabel={!isMobile}
        orientation={isMobile ? "vertical" : "horizontal"}
        connector={<CustomStepConnector />}
        sx={{ width: "100%" }}
      >
        {stepsArr?.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={(props) => (
              <CustomStepIcon {...props} isCancelled={isCancelled} icon={index + 1} />
            )}>
              <Typography sx={{ fontSize: "12px" }}>{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperProgress;
